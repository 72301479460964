import { useEffect, useState } from 'react'
import MaterialReactTable, {
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState,
} from 'material-react-table'
import { CustomDataGridState, SortItem, TableFilterItem } from '../../pages/Quote/ListQuotes'

interface ITable {
    data: any[]
    isLoading: boolean
    columns: MRT_ColumnDef<any>[],
    rowCount: number
    onDoNewRequest: Function
    useCheckboxes: boolean
}

export const DataGrid = (props: ITable) => {
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    )
    // const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState<MRT_SortingState>([{
        id: 'id',
        desc: true,
    }])
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    })

    let timeoutId: ReturnType<typeof setTimeout> | undefined

    useEffect(() => {
        // Clear the previous timeout
        clearTimeout(timeoutId)

        // Set a new timeout to apply the filter after n seconds
        // This is used so that the user has enough time to write the filter they want and to not
        timeoutId = setTimeout(() => {
            const filters: TableFilterItem[] = columnFilters.map(filter => ({
                field: filter.id,
                value: filter.value,
                operator: 'contains',
            }))
            // We just want to support one filter for now
            const sort: SortItem = sorting.map(sorting => ({
                field: sorting.id,
                order: sorting.desc ? 'desc' : 'asc',
            }))[0] || null
            const newQuotesRequest: CustomDataGridState = {
                page: pagination.pageIndex,
                size: pagination.pageSize,
                filters: filters,
                sort: sort,
            }

            props.onDoNewRequest(newQuotesRequest)
        }, 500)
    }, [columnFilters, sorting, pagination.pageSize, pagination.pageIndex])

    return (
        <div style={{ width: '100%' }}>
            <MaterialReactTable
                data={props.data}
                columns={props.columns}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 25],
                }}
                onColumnFiltersChange={setColumnFilters}
                // onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                //editMode="cell"
                rowCount={props.rowCount}
                onPaginationChange={setPagination}
                // sortModel={props.sortModel}
                // checkboxSelection={props.useCheckboxes}
                initialState={{ showColumnFilters: false }}
                manualFiltering
                manualPagination
                manualSorting
                state={{
                    columnFilters,
                    // globalFilter,
                    pagination,
                    sorting,
                    isLoading: props.isLoading,
                }}
            />
        </div>
    )
}
