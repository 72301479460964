import { ChangeEvent, createContext } from 'react'
import {
    CreateQuoteProductRequest,
    CreateQuoteRequest,
    ProductFamily,
    ProductFamilyField,
} from '../pages/Quote/CreateQuote'
import { ManageQuoteState, ProductsQuote } from './ManageQuoteProvider'

export interface ManageQuoteContextValue {
    products: ManageQuoteState
    getProductsForFamily: (familyId: number) => ProductsQuote[]
    setProductsForFamily: (familyId: number, products: ProductsQuote[]) => void
    moveProductUp: (productFamilyIndex: number, productIndex: number) => void
    moveProductDown: (productFamilyIndex: number, productIndex: number) => void
    onLoadProductFamilies: (productFamilies: ProductFamily[]) => void
    onAddCustomProduct: (customProduct: ProductsQuote, productFamily: ProductFamilyField) => void
    onProductsChanged: (
        productFamily: ProductFamilyField,
        product: ProductsQuote,
    ) => void
    onProductFamilyChanged: (
        productFamilyValue: string,
        productFamily: ProductFamilyField,
    ) => void
    onAddNewProductFamily: () => void
    onAddQuantityToProduct: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        family: ProductFamilyField,
        changedProduct: ProductsQuote,
    ) => void
    onChangeProductUnitaryPrice: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        family: ProductFamilyField,
        changedProduct: ProductsQuote,
    ) => void
    onDeleteProduct: (productReference: string, familyId: number) => void
    onCreateQuote: (createQuoteState: CreateQuoteRequest) => Promise<void>
    onUpdateQuote: (quoteId: number, createQuoteState: CreateQuoteRequest) => Promise<void>
    productsForRequest: () => CreateQuoteProductRequest[]
    divAndMoButtonClicked: (familyId: number) => void
    loadState: (manageQuoteState: ManageQuoteState) => void
    clearState: () => void
}

const initialManageQuoteContextValue: ManageQuoteContextValue = {
    products: {
        selectedProducts: [
            {
                family: {
                    id: 0,
                    value: 0,
                    name: '',
                },
                products: [],
                selectedProducts: [],
                totalPrice: 0,
                divAndMoButtonClicked: false,
            },
        ],
        showAddProductFamilyButton: false,
        productFamilies: [],
    },
    getProductsForFamily: (familyId: number) => [],
    setProductsForFamily: (familyId: number, products: ProductsQuote[]) => {
    },
    moveProductUp: (productFamilyIndex: number, productIndex: number) => {
    },
    moveProductDown: (productFamilyIndex: number, productIndex: number) => {
    },
    onLoadProductFamilies: (productFamilies: ProductFamily[]) => {
    },
    onAddCustomProduct: (customProduct: ProductsQuote, productFamily: ProductFamilyField) => {
    },
    onProductsChanged: (
        productFamily: ProductFamilyField,
        product: ProductsQuote,
    ) => {
    },
    onProductFamilyChanged: (
        productFamilyValue: string,
        productFamily: ProductFamilyField,
    ) => {
    },
    onAddNewProductFamily: () => {
    },
    onAddQuantityToProduct: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        family: ProductFamilyField,
        changedProduct: ProductsQuote,
    ) => {
    },
    onChangeProductUnitaryPrice: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        family: ProductFamilyField,
        changedProduct: ProductsQuote,
    ) => {
    },
    onDeleteProduct: (productReference: string, familyId: number) => {
    },
    onCreateQuote: async (createQuoteState: CreateQuoteRequest): Promise<void> => {
    },
    onUpdateQuote: async (quoteId: number, createQuoteState: CreateQuoteRequest): Promise<void> => {
    },
    productsForRequest: (): CreateQuoteProductRequest[] => [],
    divAndMoButtonClicked: (familyId: number) => {
    },
    loadState: (manageQuoteState: ManageQuoteState) => {
    },
    clearState: () => {
    },
}

export const ManageQuoteContext = createContext<ManageQuoteContextValue>(
    initialManageQuoteContextValue,
)
