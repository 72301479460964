import { useState } from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { MRT_ColumnDef } from 'material-react-table'
import { Product } from '../Quote/CreateQuote'
import { isNotEmpty } from '../../util/ValidationHelper'

interface ProductModalProps {
    columns: MRT_ColumnDef<Product>[];
    onClose: () => void;
    onSubmit: (values: Product) => void;
    open: boolean;
}

const CreateProductModal = ({
                                open,
                                columns,
                                onClose,
                                onSubmit,
                            }: ProductModalProps) => {
    const [newProductState, setNewProductState] = useState<Product>(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = ''
            return acc
        }, {} as any),
    )
    const [error, setError] = useState<boolean>(false)

    const handleCancel = () => {
        setError(false)
        onClose()
    }

    const handleSubmit = () => {
        // validate product
        const isReferenceValid = isNotEmpty(newProductState.reference)
        const isNameValid = isNotEmpty(newProductState.name)
        const isBaseUnitValid = isNotEmpty(newProductState.baseUnit)
        const isPriceValid = newProductState.price >= 0

        const isProductValid = isReferenceValid && isNameValid && isBaseUnitValid && isPriceValid

        if (isProductValid) {
            //put your validation logic here
            setError(false)
            onSubmit(newProductState)
            onClose()
        } else {
            setError(true)
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign='center'>Criar produto</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns
                            .filter(column => column.accessorKey != 'id')
                            .map((column) => (
                                column.accessorKey === 'active' ?
                                    <FormControlLabel
                                        required
                                        control={
                                            <Checkbox
                                                checked={newProductState.active}
                                                onChange={(e) =>
                                                    setNewProductState({
                                                        ...newProductState,
                                                        ['active']: e.target.checked,
                                                    })
                                                }
                                            />}
                                        label='Ativo'
                                    /> :
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setNewProductState({ ...newProductState, [e.target.name]: e.target.value })
                                        }
                                    />
                            ))}
                        {error ?
                            <Typography color='error'>Existe um error em pelo menos um dos campos</Typography> : ''}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button color='secondary' onClick={handleSubmit} variant='contained'>
                    Criar produto
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateProductModal