import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { ManageQuoteContext } from '../../store/manage-quote-context'
import { useHttp } from '../../hooks/use-http'
import { QuoteHeader } from '../../components/Quote/Mutate/QuoteHeader'
import { QuoteOptions } from '../../components/Quote/Mutate/QuoteOptions'
import { QuoteProducts } from '../../components/Quote/Mutate/QuoteProducts'
import { isQuoteValid } from '../../util/ValidationHelper'
import QuoteMutatedPage from './QuoteMutatedPage'

export interface CreateQuoteRequest extends QuoteHeader, QuoteOptions {
}

export interface CreateQuoteProductRequest {
    quoteIndex: number
    quoteRowIndex: number
    productId: number
    reference: string
    name: string
    productFamily: number
    price: number
    quantity: number
}

export interface ProductFamily {
    id: number
    name: string
}

export interface Product {
    id: number
    reference: string
    name: string
    price: number
    productFamily: ProductFamily
    baseUnit: string
    active: boolean
}

export interface ProductCheckout {
    product: Product
    quoteRowIndex: number
    price: number
    quantity: number
    totalPrice: number
}

export interface ProductFamilyField {
    id: number
    value: number
    name: string
}

export type BooleanObject = {
    [key: string]: boolean;
};

export interface QuoteState extends QuoteHeader, QuoteOptions {
}

interface CreateQuoteState extends QuoteState {
    isLoading: boolean,
    quoteRequestSent: boolean,
    error?: string
}

export const createQuoteRequestObject = (state: CreateQuoteState) => {
    return {
        customerHandler: state.customerHandler,
        customer: state.customer,
        address: state.address,
        postalCode: state.postalCode,
        constructionSupervisor: state.constructionSupervisor,
        paymentConditions: state.paymentConditions,
        comments: state.comments,
        subject: state.subject,
        downloadFileType: state.downloadFileType,
        generateWithTotalPriceOnly:
        state.generateWithTotalPriceOnly,
        quoteFor24hConnectionService: state.quoteFor24hConnectionService,
        construction: state.construction,
    } as CreateQuoteRequest
}

const CreateQuote = () => {
    const [state, setState] = useState<CreateQuoteState>({
        customerHandler: 'EXMO_SENHOR',
        customer: '',
        address: '',
        postalCode: '',
        downloadFileType: 'PDF_TO_PRINT',
        generateWithTotalPriceOnly: false,
        quoteFor24hConnectionService: undefined,
        constructionSupervisor: undefined,
        paymentConditions: undefined,
        construction: undefined,
        comments: undefined,
        subject: 'Proposta orçamental',
        isLoading: false,
        quoteRequestSent: false,
        error: undefined,
    })

    const [formInputsValidity, setFormInputsValidity] = useState<BooleanObject>({
        customerHandler: true,
        customer: true,
        constructionSupervisor: true,
        address: true,
        postalCode: true,
        downloadFileType: true,
        products: true,
        wasDivAndMoButtonClicked: true,
        atLeastOneExistingProductSelected: true,
    })

    const ctx = useContext(ManageQuoteContext)
    const { sendRequestAndProcessData } = useHttp()

    useEffect(() => {
        const handleUnload = () => {
            ctx.clearState()
        }

        (async () => {
            window.addEventListener('unload', handleUnload)
            await getProductFamilies()
        })()

        return () => {
            window.removeEventListener('unload', handleUnload)
        }
    }, [])

    const getProductFamilies = async () => {
        const transformProductFamilies = (productFamilies: any) => {
            const sortedData = productFamilies.sort(
                (a: ProductFamily, b: ProductFamily) =>
                    a.name.localeCompare(b.name),
            )

            ctx.onLoadProductFamilies(sortedData)
        }

        await sendRequestAndProcessData(
            {
                url: 'product/family',
            },
            transformProductFamilies,
        )
    }

    const isQuoteCreationValid = () => {
        const wasDivAndMoButtonClicked = ctx.products.selectedProducts.every(f => f.divAndMoButtonClicked)
        const quoteValidity = isQuoteValid(state, ctx, setFormInputsValidity)

        setFormInputsValidity((prevState) => ({
            ...prevState,
            wasDivAndMoButtonClicked: wasDivAndMoButtonClicked,
        }))

        return quoteValidity && wasDivAndMoButtonClicked
    }

    const createQuoteRequest = async () => {
        const formIsValid = isQuoteCreationValid()

        if (!formIsValid) {
            return
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            quoteRequestSent: true,
        }))

        await ctx.onCreateQuote(createQuoteRequestObject(state))
            .then(response => {
                setState((prevState) => ({
                    ...prevState,
                    quoteRequestSent: true,
                    isLoading: false,
                }))
                ctx.clearState()
            })
            .catch(err => {
                setState((prevState) => ({
                    ...prevState,
                    error: err.message,
                }))
            })
    }

    const inputChangedHandler = (
        value: string | boolean,
        fieldName: keyof CreateQuoteState,
    ) => {
        setState((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }))
    }

    const quoteHasProducts =
        ctx.products.selectedProducts.reduce(
            (acc, item) => acc + item.selectedProducts.length,
            0,
        ) >= 1

    const quoteCreationForm = <form onSubmit={createQuoteRequest}>
        <QuoteHeader
            inputChangedHandler={inputChangedHandler}
            data={state}
            dataValidity={formInputsValidity}
        />
        <Divider variant='middle' sx={{ margin: '1rem' }} />
        <QuoteProducts />
        {!formInputsValidity.products &&
            <Typography color='red'>Informação para os produtos inválida. Por favor preencha todos os
                campos.</Typography>}
        {!formInputsValidity.wasDivAndMoButtonClicked &&
            <Typography color='red'>É obrigatório adicionar os DIVs e MO</Typography>}
        {quoteHasProducts && (
            <Box
                sx={{
                    '& > :not(style)': { my: 2, mx: 0, width: '100%' },
                }}
            >
                <Divider variant='middle' sx={{ margin: '1rem' }} />
                <QuoteOptions data={state} dataValidity={formInputsValidity}
                              inputChangedHandler={inputChangedHandler} />
                <Button
                    variant='contained'
                    onClick={createQuoteRequest}
                    fullWidth
                    disabled={state.quoteRequestSent}
                >
                    Gerar Orçamento
                </Button>
                {state.isLoading && <CircularProgress />}
            </Box>
        )}
    </form>

    return (
        <>
            <Typography color='red'>{state.error}</Typography>
            {state.quoteRequestSent && <QuoteMutatedPage />}
            {!state.quoteRequestSent && quoteCreationForm}
        </>
    )
}

export default CreateQuote
