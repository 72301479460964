import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CreateQuote from '../pages/Quote/CreateQuote'
import ListQuotes from '../pages/Quote/ListQuotes'
import Layout from '../pages/Layout'
import { ProtectedRoute } from './ProtectedRoute'
import Login from '../pages/Login'
import NoMatch from '../pages/NoMatch'
import { LoggedRoute } from './LoggedRoute'
import EditQuote from '../pages/Quote/EditQuote'
import ProductManager from '../pages/Product/ProductManager'
import UserManager from '../pages/User/UserManager'

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='/'
                    element={
                        <ProtectedRoute>
                            <Layout />
                        </ProtectedRoute>
                    }
                >
                    <Route
                        index
                        element={
                            <ProtectedRoute>
                                <ListQuotes />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path='quotes'
                        element={
                            <ProtectedRoute>
                                <ListQuotes />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path='create-quote'
                        element={
                            <ProtectedRoute>
                                <CreateQuote />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path='quote/:id'
                        element={
                            <ProtectedRoute>
                                <EditQuote />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path='product'
                        element={
                            <ProtectedRoute>
                                <ProductManager />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path='user'
                        element={
                            <ProtectedRoute>
                                <UserManager />
                            </ProtectedRoute>
                        }
                    />

                    {/* Using path="*"" means "match anything", so this route
                        acts like a catch-all for URLs that we don't have explicit
                        routes for. */}
                    <Route path='*' element={<NoMatch />} />
                </Route>
                <Route
                    path='/login'
                    element={
                        <LoggedRoute>
                            <Login />
                        </LoggedRoute>
                    }
                />
                {/* <Route path="/signup" element={<Signup />} /> */}
            </Routes>
        </BrowserRouter>
    )
}
