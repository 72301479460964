import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ManageQuoteContext } from '../../store/manage-quote-context'
import { useHttp } from '../../hooks/use-http'
import {
    BooleanObject,
    CreateQuoteRequest,
    createQuoteRequestObject,
    Product,
    ProductCheckout,
    ProductFamily,
    ProductFamilyField,
} from './CreateQuote'
import { QuoteHeader } from '../../components/Quote/Mutate/QuoteHeader'
import { QuoteOptions } from '../../components/Quote/Mutate/QuoteOptions'
import { Box, Button, Divider, Typography } from '@mui/material'
import {
    calculateQuoteTotalPrice,
    FamilyProducts,
    ManageQuoteState,
    ProductsQuote,
} from '../../store/ManageQuoteProvider'
import { EditQuoteTab } from '../../components/Quote/Mutate/EditQuoteTab'
import { isQuoteValid } from '../../util/ValidationHelper'
import AuthContext from '../../store/auth-context'
import QuoteMutatedPage from './QuoteMutatedPage'

export interface CustomProductCheckout {
    quoteRowIndex: number
    reference: string
    name: string
    price: number
    quantity: number
    totalPrice: number
}

interface Quote extends CreateQuoteRequest {
    id: number,
    productsCheckout: ProductCheckout[],
    customProductsCheckout?: CustomProductCheckout[],
    productFamily: ProductFamily,
}

interface EditQuoteState extends Quote {
    isLoading: boolean,
    quoteRequestSent: boolean,
    error?: string
}

const EditQuote = () => {
    const [state, setState] = useState<EditQuoteState>({
        id: 0,
        customerHandler: 'EXMO_SENHOR',
        customer: '',
        address: '',
        postalCode: '',
        downloadFileType: 'PDF_TO_PRINT',
        generateWithTotalPriceOnly: false,
        quoteFor24hConnectionService: undefined,
        constructionSupervisor: undefined,
        paymentConditions: undefined,
        construction: undefined,
        comments: undefined,
        subject: 'Proposta orçamental',
        productsCheckout: [],
        customProductsCheckout: [],
        productFamily: {
            id: 0,
            name: '',
        },
        isLoading: false,
        quoteRequestSent: false,
        error: undefined,
    })
    const ctx = useContext(ManageQuoteContext)
    const authCtx = useContext(AuthContext)
    const { sendRequestAndProcessData } = useHttp()
    const { id } = useParams()
    const [formInputsValidity, setFormInputsValidity] = useState<BooleanObject>({
        customerHandler: true,
        customer: true,
        constructionSupervisor: true,
        address: true,
        postalCode: true,
        downloadFileType: true,
        products: true,
        atLeastOneExistingProductSelected: true,
    })

    useEffect(() => {
        const handleUnload = () => {
            ctx.clearState()
        }

        (async () => {
            window.addEventListener('unload', handleUnload)
            await getQuoteById()
        })()

        return () => {
            window.removeEventListener('unload', handleUnload)
        }
    }, [])

    const getQuoteById = async () => {
        const transformQuotes = (quote: Quote) => {
            setState({
                id: quote.id,
                customerHandler: quote.customerHandler ?? state.customerHandler,
                customer: quote.customer,
                address: quote.address,
                postalCode: quote.postalCode,
                downloadFileType: quote.downloadFileType,
                generateWithTotalPriceOnly: quote.generateWithTotalPriceOnly,
                quoteFor24hConnectionService: quote.quoteFor24hConnectionService,
                constructionSupervisor: quote.constructionSupervisor,
                paymentConditions: quote.paymentConditions,
                construction: quote.construction,
                comments: quote.comments,
                subject: quote.subject,
                productsCheckout: quote.productsCheckout,
                customProductsCheckout: quote.customProductsCheckout,
                productFamily: quote.productFamily,
                quoteRequestSent: false,
                isLoading: false,
                error: undefined,
            })
            const familyProducts = mapFamilyProducts(quote)
            const manageQuoteState: ManageQuoteState = {
                // Since we just have one product family per quote, we want to
                // restrict the addition of new families
                showAddProductFamilyButton: false,
                productFamilies: [quote.productFamily],
                selectedProducts: [familyProducts],
            }
            ctx.loadState(manageQuoteState)
        }

        await sendRequestAndProcessData(
            {
                url: `quote/${id}`,
            },
            transformQuotes,
        )
    }

    const mapFamilyProducts = (quote: Quote): FamilyProducts => {
        const products: ProductsQuote[] = quote.productsCheckout.map((product: ProductCheckout) =>
            productCheckoutToProductQuote(product.product, product.quoteRowIndex, product.price, product.quantity, product.totalPrice),
        )

        const customProducts: ProductsQuote[] = quote.customProductsCheckout?.map((product: CustomProductCheckout) =>
            customProductCheckoutToProductQuote(product, product.quantity, product.totalPrice),
        ) ?? []

        products.push(...customProducts)
        products.sort((a: ProductsQuote, b: ProductsQuote) => a.quoteRowIndex - b.quoteRowIndex)

        return {
            family: productFamilyToProductFamilyField(quote.productFamily),
            products: [],
            selectedProducts: products,
            totalPrice: calculateQuoteTotalPrice(products),
            divAndMoButtonClicked: true,
        }
    }

    const productFamilyToProductFamilyField = (productFamily: ProductFamily): ProductFamilyField => {
        return {
            id: 0,
            value: productFamily.id,
            name: productFamily.name,
        }
    }

    const productCheckoutToProductQuote = (product: Product, quoteRowIndex: number, price: number, quantity: number, totalPrice: number): ProductsQuote => {
        return {
            id: product.id,
            quoteRowIndex: quoteRowIndex,
            label: product.name,
            reference: product.reference,
            price: price,
            quantity: quantity,
            totalPrice: totalPrice,
            active: product.active,
        }
    }

    const customProductCheckoutToProductQuote = (product: CustomProductCheckout, quantity: number, totalPrice: number): ProductsQuote => {
        return {
            quoteRowIndex: product.quoteRowIndex,
            id: 0,
            label: product.name,
            reference: product.reference,
            price: product.price,
            quantity: quantity,
            totalPrice: totalPrice,
            active: true,
        }
    }

    const inputChangedHandler = (
        value: string | boolean,
        fieldName: keyof CreateQuoteRequest,
    ) => {
        setState((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }))
    }

    const quoteHasProducts =
        ctx.products.selectedProducts.reduce(
            (acc, item) => acc + item.selectedProducts.length,
            0,
        ) >= 1

    const editQuoteRequest = async () => {
        const formIsValid = isQuoteValid(state, ctx, setFormInputsValidity)

        if (!formIsValid) {
            return
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            quoteRequestSent: true,
        }))

        await ctx.onUpdateQuote(state.id, createQuoteRequestObject(state))
            .then(response => {
                setState((prevState) => ({
                    ...prevState,
                    quoteRequestSent: true,
                    isLoading: false,
                }))
                ctx.clearState()
            })
            .catch(err => {
                setState((prevState) => ({
                    ...prevState,
                    error: err.message,
                }))
            })
    }

    const quoteEditPage = <>
        <QuoteHeader data={state} dataValidity={formInputsValidity} inputChangedHandler={inputChangedHandler} />
        <Divider variant='middle' sx={{ margin: '1rem' }} />
        <EditQuoteTab />
        {!formInputsValidity.products &&
            <Typography color='red'>Informação para os produtos inválida. Por favor preencha todos os
                campos.</Typography>}
        <Divider variant='middle' sx={{ margin: '1rem' }} />
        {quoteHasProducts && (
            <Box
                component='form'
                sx={{
                    '& > :not(style)': { my: 2, mx: 0, width: '100%' },
                }}
            >
                <QuoteOptions data={state} dataValidity={formInputsValidity}
                              inputChangedHandler={inputChangedHandler} />
                <Button
                    variant='contained'
                    onClick={editQuoteRequest}
                    fullWidth
                >
                    Atualizar e Gerar Orçamento
                </Button>
            </Box>
        )}
    </>

    return (
        <>
            <Typography color='red'>{state.error}</Typography>
            {state.quoteRequestSent && <QuoteMutatedPage />}
            {!state.quoteRequestSent && quoteEditPage}
        </>
    )
}

export default EditQuote