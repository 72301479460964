import { Tab, TabList, TabPanel, Tabs } from '@mui/joy'
import { useContext } from 'react'
import { ManageQuoteContext } from '../../../store/manage-quote-context'
import { Box } from '@mui/material'
import { ProductSelector } from '../../ProductSelector'
import { QuoteProductsTable } from './QuoteProductsTable'

export const EditQuoteTab = () => {
    const ctx = useContext(ManageQuoteContext)

    const tabContent = ctx.products.selectedProducts.map((familyProducts) => (
        <TabPanel
            value={familyProducts.family.id}
            key={familyProducts.family.id + '1'}
        >
            <Box
                sx={{
                    '& > :not(style)': { my: 2, width: '100%' },
                }}
            >
                <ProductSelector
                    key={
                        familyProducts.family.id +
                        familyProducts.family.value
                    }
                    productFamily={familyProducts.family}
                    editMode={true}
                />

                <QuoteProductsTable
                    key={familyProducts.family.id + '2'}
                    products={familyProducts.products}
                    selectedProducts={familyProducts.selectedProducts}
                    family={familyProducts.family}
                    totalPrice={familyProducts.totalPrice}
                    divAndMoButtonClicked={familyProducts.divAndMoButtonClicked}
                />
            </Box>
        </TabPanel>
    ))

    return (
        <Tabs
            aria-label='Basic tabs'
            defaultValue={0}
            sx={{ borderRadius: 'lg' }}
        >
            <TabList>
                {ctx.products.selectedProducts.map((option) => (
                    <Tab key={option.family.id}>
                        {option.family.name || 'Categoria'}
                    </Tab>
                ))}
            </TabList>
            {tabContent}
        </Tabs>
    )
}
