import { Quote, User } from '../../../pages/Quote/ListQuotes'
import { useEffect, useMemo, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useHttp } from '../../../hooks/use-http'
import EditIcon from '@mui/icons-material/Edit'
import { Link } from 'react-router-dom'
import { DataGrid } from '../../UI/DataGrid'
import { MRT_ColumnDef } from 'material-react-table'
import { QuoteOptions } from '../Mutate/QuoteOptions'
import { BooleanObject } from '../../../pages/Quote/CreateQuote'


interface IQuoteTable {
    quotes: Quote[]
    totalElements: number
    onGetQuotes: Function
    isLoading: boolean
}

enum QuoteDownloadType {
    QUOTE, QUOTE_GROUP
}

interface QuoteTableState {
    quotes: QuoteList[],
    isDownloadQuoteGroupModalOpen: boolean
    lastClickedQuoteGroupId: number
    lastClickedQuoteId: number
    quoteDownloadType: QuoteDownloadType
}

interface QuoteList {
    id: string
    createdBy: User
    quoteGroupId: string
    customer: string;
    date: Date
    address: string
    postalCode: string
    family: string
}

const defaultDownloadQuoteState = (): QuoteOptions => {
    return {
        downloadFileType: 'PDF_FOR_EMAIL',
        generateWithTotalPriceOnly: false,
        quoteFor24hConnectionService: undefined,
        comments: undefined,
    }
}

export const QuoteTable = (props: IQuoteTable) => {
    const [state, setState] = useState<QuoteTableState>({
        quotes: [],
        isDownloadQuoteGroupModalOpen: false,
        lastClickedQuoteGroupId: 0,
        lastClickedQuoteId: 0,
        quoteDownloadType: QuoteDownloadType.QUOTE,
    })
    const [downloadQuoteState, setDownloadQuoteState] = useState<QuoteOptions>(defaultDownloadQuoteState())
    const [formInputsValidity] = useState<BooleanObject>({
        downloadFileType: true,
    })
    const { sendDownloadResourceRequest } = useHttp()

    useEffect(() => {
        const data = props.quotes.map((quote: Quote) => {
            return {
                id: quote.id,
                createdBy: quote.createdBy,
                quoteGroupId: quote.quoteGroupId,
                customer: quote.customer,
                date: quote.date,
                address: quote.address,
                postalCode: quote.postalCode,
                family: quote.productFamily.name,
            } as QuoteList
        })

        setState((prevState) => ({
            ...prevState,
            quotes: data,
        }))
    }, [props.quotes])

    const inputChangedHandlerDownloadQuoteState = (
        value: string | boolean,
        fieldName: keyof QuoteOptions,
    ) => {
        setDownloadQuoteState((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }))
    }

    function quoteHasBeenDownloadedHandler() {
        setState((prevState) => ({
            ...prevState,
            isDownloadQuoteGroupModalOpen: false,
        }))

        setDownloadQuoteState(defaultDownloadQuoteState)
    }

    const generateQuote = async () => {
        const { headers, downloadOptions } = buildRequestHeaderAndBody()

        sendDownloadResourceRequest({
            url: `quote/${state.lastClickedQuoteId}`,
            method: 'POST',
            headers: headers,
            body: downloadOptions,
        })

        quoteHasBeenDownloadedHandler()
    }

    const generateQuoteByQuoteGroupId = async () => {
        const { headers, downloadOptions } = buildRequestHeaderAndBody()

        sendDownloadResourceRequest({
            url: `quote/group/${state.lastClickedQuoteGroupId}`,
            method: 'POST',
            headers: headers,
            body: downloadOptions,
        })

        quoteHasBeenDownloadedHandler()
    }

    function buildRequestHeaderAndBody() {
        const headers = {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/json',
        }

        const downloadOptions = {
            downloadFileType: downloadQuoteState.downloadFileType,
            generateWithTotalPriceOnly: downloadQuoteState.generateWithTotalPriceOnly,
            quoteFor24hConnectionService: downloadQuoteState.quoteFor24hConnectionService,
        }
        return { headers, downloadOptions }
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
        { accessorKey: 'id', header: 'Ref', filterable: false, sortable: false, size: 50 },
        {
            accessorKey: 'createdBy',
            id: 'createdBy',
            header: 'Criado por',
            Cell: ({ cell, renderedCellValue, column, row, table }) => {
                const firstName = row.original.createdBy.firstName
                const lastName = row.original.createdBy.lastName

                return <>{firstName} {lastName}</>
            },
            size: 100,
        },
        { accessorKey: 'customer', header: 'Cliente', size: 125 },
        { accessorKey: 'date', header: 'Data', filterable: false, sortable: false, size: 75 },
        { accessorKey: 'address', header: 'Morada', size: 150 },
        {
            accessorKey: 'postalCode',
            header: 'Código Postal'
            , size: 125,
        },
        {
            accessorKey: 'family',
            header: 'Categoria', size: 150,
        },
        {
            accessorKey: 'download',
            header: 'Download',
            Cell: ({ cell, renderedCellValue, column, row, table }) => {
                const quoteId = row.original.id as number

                return (
                    <Button
                        variant='contained'
                        onClick={e => handleOpenDownloadQuoteModal(quoteId)}
                    >
                        Download
                    </Button>

                )
            },
            filterable: false,
            sortable: false,
            size: 100,
        },
        {
            accessorKey: 'downloadGroup',
            header: 'Download Grupo',
            Cell: ({ cell, renderedCellValue, column, row, table }) => {
                const quoteGroupId = row.original.quoteGroupId as number

                return (
                    <Button
                        variant='contained'
                        onClick={e => handleOpenDownloadQuoteGroupModal(quoteGroupId)}
                    >
                        Download grupo
                    </Button>
                )
            },
            filterable: false,
            sortable: false,
        },
        {
            accessorKey: 'edit',
            header: 'Editar',
            Cell: ({ cell, renderedCellValue, column, row, table }) => {
                const quoteId = row.original.id as number

                return (
                    <Link to={`quote/${quoteId}`}>
                        <EditIcon />
                    </Link>
                )
            },
            filterable: false,
            sortable: false, size: 25,
        },
    ], [])

    const handleOpenDownloadQuoteModal = (quoteId: number) => {
        setState((prevState) => ({
            ...prevState,
            isDownloadQuoteGroupModalOpen: true,
            lastClickedQuoteId: quoteId,
            quoteDownloadType: QuoteDownloadType.QUOTE,
        }))
    }

    const handleOpenDownloadQuoteGroupModal = (newQuoteGroupId: number) => {
        setState((prevState) => ({
            ...prevState,
            isDownloadQuoteGroupModalOpen: true,
            lastClickedQuoteGroupId: newQuoteGroupId,
            quoteDownloadType: QuoteDownloadType.QUOTE_GROUP,
        }))
    }

    const handleCloseDownloadQuoteGroupModal = () => {
        setState((prevState) => ({
            ...prevState,
            isDownloadQuoteGroupModalOpen: false,
        }))

        setDownloadQuoteState(defaultDownloadQuoteState)
    }

    const downloadQuoteGroupModal = <Dialog open={state.isDownloadQuoteGroupModalOpen}>
        <DialogTitle
            textAlign='center'>{state.quoteDownloadType === QuoteDownloadType.QUOTE ? 'Download do orçamento' : 'Download de grupo de orçamentos'}</DialogTitle>
        <DialogContent>
            <QuoteOptions data={downloadQuoteState} dataValidity={formInputsValidity}
                          inputChangedHandler={inputChangedHandlerDownloadQuoteState} />
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={handleCloseDownloadQuoteGroupModal}>Cancelar</Button>
            <Button color='secondary' variant='contained'
                    onClick={state.quoteDownloadType === QuoteDownloadType.QUOTE ? generateQuote : generateQuoteByQuoteGroupId}>
                Gerar orçamento
            </Button>
        </DialogActions>
    </Dialog>

    return (
        <>
            {downloadQuoteGroupModal}
            <DataGrid
                data={state.quotes}
                columns={columns}
                isLoading={props.isLoading}
                rowCount={props.totalElements}
                onDoNewRequest={props.onGetQuotes}
                useCheckboxes={false}
            />
        </>
    )
}