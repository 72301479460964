import { Box, Typography } from '@mui/material'
import { CreateQuoteTab } from './CreateQuoteTab'

export const QuoteProducts = () => {
    return (
        <Box
            sx={{
                '& > :not(style)': { m: 2, width: '100%' },
            }}
        >
            <Typography
                gutterBottom
                variant='h4'
                component='div'
                sx={{ marginBottom: '1rem' }}
            >
                Produtos por categoria
            </Typography>
            <CreateQuoteTab />
        </Box>
    )
}