import { useEffect, useState } from 'react'
import { ProductCheckout, ProductFamily } from './CreateQuote'
import { useHttp } from '../../hooks/use-http'
import { QuoteTable } from '../../components/Quote/List/QuoteTable'

interface IListQuotes {
    quotes: Quote[]
    totalElements: number
}

export interface CustomDataGridState {
    page: number,
    size: number,
    filters?: TableFilterItem[],
    sort?: SortItem
}

export interface TableFilterItem {
    /**
     * Must be unique.
     * Only useful when the model contains several items.
     */
    id?: number | string;
    /**
     * The column from which we want to filter the rows.
     */
    field: string;
    /**
     * The filtering value.
     * The operator filtering function will decide for each row if the row values is correct compared to this value.
     */
    value?: any;
    /**
     * The name of the operator we want to apply.
     */
    operator: string;
}

export interface SortItem {
    field: string;
    order: string;
}

export interface User {
    id: String,
    username: String,
    firstName: String,
    lastName: String,
    active: Boolean,
    roles: Set<String>
}

export interface Quote {
    id: string
    createdBy: User
    quoteGroupId: string
    customerHandler: string
    customer: string
    address: string
    postalCode: string
    productFamily: ProductFamily
    productsCheckout: ProductCheckout[]
    date: Date
    paymentConditions?: string
    comments?: string
    subject?: string
}

const ListQuotes = () => {
    const [state, setState] = useState<IListQuotes>({
        quotes: [],
        totalElements: 0,
    })

    const { isLoading, sendRequestAndProcessData } = useHttp()

    useEffect(() => {
        (async () => {
            await getQuotes({
                page: 0,
                size: 10,
                sort: {
                    field: 'id',
                    order: 'desc',
                },
            })
        })()
    }, [])

    const getQuotes = async (gridState: CustomDataGridState) => {
        const transformQuotes = (quotes: any) => {
            setState({
                quotes: quotes.content,
                totalElements: quotes.totalElements,
            })
        }

        await sendRequestAndProcessData(
            {
                url: 'quote/get',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    page: gridState.page,
                    size: gridState.size,
                    filters: gridState.filters,
                    sort: gridState.sort,
                },
            },
            transformQuotes,
        )
    }

    return (
        <>
            <QuoteTable
                quotes={state.quotes}
                isLoading={isLoading}
                totalElements={state.totalElements}
                onGetQuotes={getQuotes}
            />
        </>
    )
}

export default ListQuotes
