import { createContext, useCallback } from 'react'
import { useHttp } from '../hooks/use-http'
import { getUserRoles, isTokenValid } from '../util/JwtHelper'

export interface AuthContextValue {
    onRegister: (username: string, password: string) => void
    onLogin: (username: string, password: string) => Promise<void>
    onLogout: () => void
    currentUserToken: () => string | null
    hasAdminRole: () => boolean
    hasModeratorRole: () => boolean
    hasUserRole: () => boolean
}

export enum UserRoles {
    ROLE_USER = 'ROLE_USER',
    ROLE_MODERATOR = 'ROLE_MODERATOR',
    ROLE_ADMIN = 'ROLE_ADMIN'
}

const initialAuthContextValue: AuthContextValue = {
    onRegister: (username: string, password: string) => {
    },
    onLogin: (username: string, password: string): Promise<void> => Promise.resolve(),
    onLogout: () => {
    },
    currentUserToken: () => null,
    hasAdminRole: () => false,
    hasModeratorRole: () => false,
    hasUserRole: () => false,
}

export const AuthContext = createContext<AuthContextValue>(
    initialAuthContextValue,
)

export const AuthContextProvider = ({ children }: any) => {
    const { isLoading, error, sendRequestAndProcessData, status } = useHttp()
    const userLocalStorageKey = 'user-token'

    const registerHandler = (username: string, password: string) => {
        sendRequestAndProcessData(
            {
                url: 'auth/signup',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    username: username,
                    password: password,
                },
            },
            () => {
            },
        )
    }

    const loginHandler = useCallback(async (username: string, password: string): Promise<void> => {
        const transformData = (jwt: any) => {
            localStorage.setItem(userLocalStorageKey, jwt.token)
        }

        await sendRequestAndProcessData(
            {
                url: 'auth/signin',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    username: username,
                    password: password,
                },
            },
            transformData,
        ).catch((err) => {
            throw new Error(err)
        })
    }, [])

    const logoutHandler = () => {
        localStorage.removeItem(userLocalStorageKey)
    }

    function getUserToken() {
        const userToken = localStorage.getItem(userLocalStorageKey)

        // if (userToken === null) {
        //     throw new Error('Token not found')
        // }

        return userToken || ''
    }

    const getCurrentUserToken = (): string | null => {
        const userToken = getUserToken()
        const isValidToken = isTokenValid(userToken)
        if (!isValidToken) {
            localStorage.removeItem(userLocalStorageKey)
            return null
        }
        return userToken
    }

    const hasAdminRole = (): boolean => {
        const userToken = getUserToken()
        return getUserRoles(userToken).some(role => role === UserRoles.ROLE_ADMIN)
    }

    const hasModeratorRole = (): boolean => {
        const userToken = getUserToken()
        return getUserRoles(userToken).some(role => role === UserRoles.ROLE_MODERATOR || role === UserRoles.ROLE_ADMIN)
    }

    const hasUserRole = (): boolean => {
        const userToken = getUserToken()
        return getUserRoles(userToken).some(role => role === UserRoles.ROLE_USER || role === UserRoles.ROLE_MODERATOR || role === UserRoles.ROLE_ADMIN)
    }

    return (
        <AuthContext.Provider
            value={{
                onRegister: registerHandler,
                onLogout: logoutHandler,
                onLogin: loginHandler,
                currentUserToken: getCurrentUserToken,
                hasAdminRole: hasAdminRole,
                hasModeratorRole: hasModeratorRole,
                hasUserRole: hasUserRole,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
