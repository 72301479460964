import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import { ChangeEvent, KeyboardEvent, useContext, useState } from 'react'
import AuthContext from '../store/auth-context'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { BooleanObject } from './Quote/CreateQuote'
import { isNotEmpty } from '../util/ValidationHelper'

interface ILogin {
}

interface LoginState {
    username: string
    password: string
    isLoading: boolean
    error?: boolean
}

const Login = (props: ILogin) => {
    const [state, setState] = useState<LoginState>({
        username: '',
        password: '',
        isLoading: false,
        error: false,
    })
    const [formInputsValidity, setFormInputsValidity] = useState<BooleanObject>({
        username: true,
        password: true,
    })

    const ctx = useContext(AuthContext)

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            login()
        }
    }

    const inputChangedHandler = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: keyof LoginState,
    ) => {
        setState((prevState) => ({
            ...prevState,
            [fieldName]: event.target.value,
        }))
    }

    function isLoginValid() {
        const enteredUsernameHandlerIsValid = isNotEmpty(state.username)
        const enteredPasswordIsValid = isNotEmpty(state.password)

        setFormInputsValidity({
            username: enteredUsernameHandlerIsValid,
            password: enteredPasswordIsValid,
        })

        const formIsValid =
            enteredUsernameHandlerIsValid &&
            enteredPasswordIsValid

        return formIsValid
    }

    const login = async () => {
        const formIsValid = isLoginValid()

        if (!formIsValid) {
            return
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }))

        await ctx.onLogin(
            state.username,
            state.password,
        ).catch(err => {
            setState((prevState) => ({
                ...prevState,
                error: true,
            }))

            return
        })

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
        }))
    }

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    Login
                </Typography>
                <Box
                    component='form'
                    sx={{ mt: 1 }}
                >
                    <TextField
                        id='username'
                        error={!formInputsValidity.username}
                        helperText={!formInputsValidity.username ? 'Introduza um utilizador' : ''}
                        margin='normal'
                        required
                        fullWidth
                        label='Nome de utilizador'
                        name='username'
                        autoComplete='username'
                        autoFocus
                        onChange={(event) =>
                            inputChangedHandler(event, 'username')
                        }
                        onKeyDown={handleKeyDown}
                    />
                    <TextField
                        id='password'
                        error={!formInputsValidity.password}
                        helperText={!formInputsValidity.password ? 'Introduza uma password' : ''}
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        autoComplete='current-password'
                        onChange={(event) =>
                            inputChangedHandler(event, 'password')
                        }
                        onKeyDown={handleKeyDown}
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        onClick={login}
                    >
                        Entrar
                    </Button>
                    <Grid container>
                        {
                            state.isLoading && <>
                                <Typography component='h5' variant='h5'>Autenticando...</Typography>
                                <CircularProgress />
                            </>
                        }
                        {state.error && (
                            <Typography component='h5' variant='h5' color='red'>
                                Houve um error ao tentar fazer login. Verifique se colocou os dados corretos ou contact
                                o administrador!
                            </Typography>
                        )}
                        {/*<Link href='#' variant='body2'>*/}
                        {/*    Forgot password?*/}
                        {/*</Link>*/}
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}

export default Login
