import { MenuItem, TextField } from '@mui/material'
import { useContext, useEffect } from 'react'
import { ManageQuoteContext } from '../store/manage-quote-context'
import { FamilyProducts } from '../store/ManageQuoteProvider'

interface IProductFamilySelector {
    familyProducts: FamilyProducts
}

export const ProductFamilySelector = (props: IProductFamilySelector) => {
    const ctx = useContext(ManageQuoteContext)
    const productFamilyValue = props.familyProducts.family.value === 0 ? '' : props.familyProducts.family.value

    useEffect(() => console.log('AGAIN'), [])

    return (
        <TextField
            id={`product-family-${props.familyProducts.family.id}`}
            select
            label='Categoria de produtos'
            onChange={(event) =>
                ctx.onProductFamilyChanged(
                    event.target.value,
                    props.familyProducts.family,
                )
            }
            fullWidth
            value={productFamilyValue}
        >
            {ctx.products.productFamilies.map((option) => (
                <MenuItem key={option.name} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    )
}
