import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
// import { Option, Select } from '@mui/joy'
import { BooleanObject } from '../../../pages/Quote/CreateQuote'
import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../../../store/auth-context'
import { enumGetKeyByValue } from '../../../util/EnumHelper'

export interface QuoteOptions {
    downloadFileType: DownloadOptionsKey
    generateWithTotalPriceOnly: boolean
    quoteFor24hConnectionService?: TwentyFourHourConnectionServiceQuoteType
    comments?: string
}

interface IQuoteOptions {
    data: QuoteOptions
    dataValidity: BooleanObject
    inputChangedHandler: Function
}

export enum DownloadOptions {
    PDF_TO_PRINT = 'Orçamento para imprimir',
    PDF_FOR_EMAIL = 'Orçamento para enviar por email',
    EXCEL_TO_PRINT = 'Orçamento para imprimir - Excel',
    EXCEL_FOR_EMAIL = 'Orçamento para enviar por email - Excel'
}

export type DownloadOptionsKey = keyof typeof DownloadOptions

const DOWNLOAD_OPTIONS_KEYS = new Map<DownloadOptions, DownloadOptionsKey>(
    Object.entries(DownloadOptions).map(([key, value]: [string, DownloadOptions]) => [value, key as DownloadOptionsKey]),
)

const downloadFileTypePolicies = (authCtx: AuthContextValue) => new Map<DownloadOptionsKey, boolean>(
    [
        ['PDF_TO_PRINT', authCtx.hasUserRole()],
        ['PDF_FOR_EMAIL', authCtx.hasUserRole()],
        ['EXCEL_TO_PRINT', authCtx.hasAdminRole()],
        ['EXCEL_FOR_EMAIL', authCtx.hasAdminRole()],
    ],
)

export enum TwentyFourHourConnectionServiceQuoteType {
    QUOTE_WITH_APP = 'Proposta ligação 24 horas c/App',
    QUOTE_WITH_APP_AND_CARD = 'Proposta ligação 24 horas c/App e cartão'
}

export type TwentyFourHourConnectionServiceQuoteTypeKey = keyof typeof TwentyFourHourConnectionServiceQuoteType


// todo throw instead of returning false
export const canDisplayDownloadFileType = (downloadOption: string, authCtx: AuthContextValue): boolean => {
    return downloadFileTypePolicies(authCtx).get(downloadOption as DownloadOptionsKey)?.valueOf() ?? false
}

export const QuoteOptions = (props: IQuoteOptions) => {
    const { downloadFileType, generateWithTotalPriceOnly, quoteFor24hConnectionService, comments } = props.data
    const {
        downloadFileType: downloadFileTypeValidity,
    } = props.dataValidity
    const ctx = useContext(AuthContext)

    return (
        <Stack>
            <Typography
                gutterBottom
                variant='h4'
                component='div'
                sx={{ marginBottom: '1rem' }}
            >
                Opções de download
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }} error={!downloadFileTypeValidity}>
                <InputLabel id='downloadFileType-label'>Download como:</InputLabel>
                <Select
                    id='downloadFileType'
                    value={downloadFileType}
                    label='Download como:'
                    onChange={(e, newValue) =>
                        props.inputChangedHandler(
                            e.target.value ?? '',
                            'downloadFileType',
                        )
                    }
                    fullWidth
                >
                    {
                        Object.values(DownloadOptions)
                            .filter(option => canDisplayDownloadFileType(enumGetKeyByValue(DownloadOptions, option) as string, ctx))
                            .map((option) => (
                                <MenuItem
                                    key={enumGetKeyByValue(DownloadOptions, option)}
                                    value={enumGetKeyByValue(DownloadOptions, option)}
                                >
                                    {option}
                                </MenuItem>
                            ))
                    }
                </Select>
                {
                    downloadFileTypeValidity ? '' :
                        <FormHelperText>Selecione o tipo que ficheiro que pretende fazer download</FormHelperText>
                }

            </FormControl>
            <FormControlLabel
                sx={{ flexDirection: 'row', margin: '0' }}
                control={
                    <Checkbox
                        value={generateWithTotalPriceOnly}
                        checked={generateWithTotalPriceOnly}
                        onChange={(event) =>
                            props.inputChangedHandler(
                                event.target.checked,
                                'generateWithTotalPriceOnly',
                            )
                        }
                    />
                }
                label='Gerar orçamento apenas com preço total'
                labelPlacement='start'
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id='quoteFor24hConnectionService-label'>Proposta ligação 24 horas:</InputLabel>
                <Select
                    id='quoteFor24hConnectionService'
                    value={quoteFor24hConnectionService}
                    label='Proposta ligação 24 horas:'
                    onChange={(e, newValue) =>
                        props.inputChangedHandler(
                            e.target.value ?? '',
                            'quoteFor24hConnectionService',
                        )
                    }
                    fullWidth
                >
                    {
                        Object.values(TwentyFourHourConnectionServiceQuoteType)
                            .map((option) => (
                                <MenuItem
                                    key={enumGetKeyByValue(TwentyFourHourConnectionServiceQuoteType, option)}
                                    value={enumGetKeyByValue(TwentyFourHourConnectionServiceQuoteType, option)}
                                >
                                    {option}
                                </MenuItem>
                            ))
                    }
                </Select>
            </FormControl>

        </Stack>
    )
}