import { CheckCircle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

const QuoteMutatedPage = () => {
    return <Box>
        <CheckCircle fontSize='large' color='success' />
        <br />
        <Typography>Orçamento gerado com sucesso! O download começará em breve.</Typography>
    </Box>
}

export default QuoteMutatedPage