/**
 * Retrieves the key of an enum object based on its value.
 * F.e. for COMPANY = 'Empresa' we pass Empresa as an arg and get COMPANY
 * @param {object} enumObject - The enum object to search in.
 * @param {*} value - The value to search for.
 * @returns {string | undefined} - The key of the enum object that matches the value, or undefined if no match is found.
 */
export const enumGetKeyByValue = (enumObject: any, value: any): string | undefined => {
    for (const key in enumObject) {
        if (enumObject.hasOwnProperty(key)) {
            if (enumObject[key] === value) {
                return key
            }
        }
    }

    return undefined
}