import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import MaterialReactTable, { MaterialReactTableProps, MRT_Cell, MRT_ColumnDef } from 'material-react-table'
import { useHttp } from '../../hooks/use-http'
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Tooltip,
} from '@mui/material'
import { Cancel, CheckCircle, Edit } from '@mui/icons-material'
import { UserRoles } from '../../store/auth-context'
import CreateUserModal from './CreateUserModal'

export interface UserToManage {
    id: number
    username: string
    password: string
    firstName: string
    lastName: string
    active: boolean
    roles: UserRoles[]
}

const UserManager = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [tableData, setTableData] = useState<UserToManage[]>([])
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({})
    const { isLoading, sendRequestAndProcessData, sendDownloadResourceRequest } = useHttp()

    useEffect(() => {
        (async () => {
            await getUsers()
        })()
    }, [])

    const getUsers = async () => {
        const transformUsers = (users: UserToManage[]) => {
            setTableData(users)
        }

        await sendRequestAndProcessData(
            {
                url: 'user',
            },
            transformUsers,
        )
    }

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<UserToManage>,
        ): MRT_ColumnDef<UserToManage>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = cell.column.id === 'roles'
                        ? validateRoles(event.target.value)
                        : validateRequired(event.target.value)
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} não é válido`,
                        })
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id]
                        setValidationErrors({
                            ...validationErrors,
                        })
                    }
                },
            }
        },
        [validationErrors],
    )

    const createUser = async (user: UserToManage) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        await sendRequestAndProcessData(
            {
                url: `auth/signup`,
                method: 'POST',
                headers: headers,
                body: user,
            },
            () => {
            },
        )
    }

    const updateUser = async (userId: string, user: UserToManage) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        await sendRequestAndProcessData(
            {
                url: `user/${userId}`,
                method: 'PUT',
                headers: headers,
                body: user,
            },
            () => {
            },
        )
    }

    const handleCreateNewRow = async (values: UserToManage) => {
        tableData.push(values)
        await createUser(values)
        setTableData([...tableData])
    }

    const handleSaveRowEdits: MaterialReactTableProps<UserToManage>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                tableData[row.index] = values
                const userId: string = row.getValue('id')

                if (values.roles.length == 0) {
                    const rolesCell = row.getAllCells().find(t => t.column.id == 'roles')!!.id

                    setValidationErrors({
                        ...validationErrors,
                        [rolesCell]: `Permissões não é válido`,
                    })
                } else {
                    await updateUser(userId, values)
                    //send/receive api updates here, then refetch or update local table data for re-render
                    setTableData([...tableData])
                    exitEditingMode() //required to exit editing mode and close modal
                }
            }
        }

    const handleCancelRowEdits = () => {
        setValidationErrors({})
    }

    const columns = useMemo<MRT_ColumnDef<UserToManage>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'username',
                header: 'Utilizador',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'firstName',
                header: 'Primeiro Nome',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'lastName',
                header: 'Último nome',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'roles',
                header: 'Permissões',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => (
                    (cell.getValue() as UserRoles[]).join(', ')
                ),
                Edit: ({ cell, row, column }) => {
                    const [userRolesState, setUserRolesState] = useState<UserRoles[]>(cell.getValue() as UserRoles[])

                    const onChange = (event: SelectChangeEvent<UserRoles[]>, child: ReactNode) => {
                        const newUserRoles = event.target.value as UserRoles[]
                        setUserRolesState(newUserRoles)
                        row._valuesCache[column.id] = newUserRoles
                    }

                    return <FormControl>
                        <InputLabel id='demo-multiple-checkbox-label-1'>Permissões</InputLabel>
                        <Select
                            id={cell.id}
                            className={cell.id}
                            multiple
                            value={userRolesState}
                            onChange={onChange}
                            input={<OutlinedInput label='Tag' />}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {
                                (Object.keys(UserRoles))
                                    .map(role =>
                                        <MenuItem key={role} value={role}>
                                            <Checkbox
                                                checked={(cell.getValue() as UserRoles[]).includes(role as UserRoles)} />
                                            <ListItemText primary={role} />
                                        </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                },
            },
            {
                accessorKey: 'active',
                header: 'Ativo',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => (
                    cell.getValue() as boolean ?
                        <CheckCircle fontSize='large' color='success' /> :
                        <Cancel fontSize='large' color='error' />
                ),
                Edit: ({ cell, row, column }) => {
                    const [activeState, setActiveState] = useState<boolean>(cell.getValue() as boolean)

                    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                        const newActive = event.target.checked
                        setActiveState(newActive)
                        row._valuesCache[column.id] = newActive
                    }

                    return <FormControlLabel
                        required
                        control={
                            <Checkbox
                                checked={activeState}
                                onChange={onChange}
                            />}
                        label='Ativo'
                    />
                },
            },

        ],
        [getCommonEditTextFieldProps],
    )

    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode='modal' //default
                enableColumnOrdering
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                renderRowActions={({ row, table }) => (
                    <Tooltip arrow placement='left' title='Edit'>
                        <IconButton onClick={() => table.setEditingRow(row)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color='secondary'
                        onClick={() => setModalOpen(true)}
                        variant='contained'
                    >
                        Criar utilizador
                    </Button>
                )}
                state={{
                    isLoading: isLoading,
                }}
            />
            <CreateUserModal
                columns={columns}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    )
}

const validateRequired = (value: string) => !!value.length

const validateRoles = (roles: string) => roles.length > 2

export default UserManager