import { IconButton, TableCell, TableRow, TextField, Typography } from '@mui/material'
import { AddCircle, Delete } from '@mui/icons-material'
import { ProductsQuote } from '../../../store/ManageQuoteProvider'
import { useContext, useState } from 'react'
import { ManageQuoteContext } from '../../../store/manage-quote-context'
import { ProductFamilyField } from '../../../pages/Quote/CreateQuote'
import { isNotEmpty } from '../../../util/ValidationHelper'

interface IQuoteProductsTableCustomProduct {
    onCustomProductAddedHandler: Function,
    productFamily: ProductFamilyField
}

export const QuoteProductsTableCustomProduct = (props: IQuoteProductsTableCustomProduct) => {
    const [state, setState] = useState<ProductsQuote>({
        id: 0,
        quoteRowIndex: 0,
        label: '',
        reference: '',
        price: undefined,
        quantity: undefined,
        totalPrice: 0,
        active: true,
    })
    const [isCustomProductValid, setIsCustomProductValid] = useState(true)
    const ctx = useContext(ManageQuoteContext)

    const addCustomProduct = () => {
        const enteredReferenceIsValid = isNotEmpty(state.reference)
        const enteredLabelIsValid = isNotEmpty(state.label)
        const enteredPriceIsValid = state.price != undefined && state.price > 0
        const enteredQuantityIsValid = state.quantity != undefined && state.quantity > 0

        const isCustomProductValid = enteredReferenceIsValid && enteredLabelIsValid && enteredPriceIsValid && enteredQuantityIsValid

        if (!isCustomProductValid) {
            setIsCustomProductValid(false)
            return
        }

        setIsCustomProductValid(true)
        ctx.onAddCustomProduct(state, props.productFamily)
        props.onCustomProductAddedHandler()
    }

    const deleteCustomProduct = () => {
        props.onCustomProductAddedHandler()
    }

    const inputChangedHandler = (
        value: any,
        fieldName: keyof ProductsQuote,
    ) => {
        let totalPrice = state.totalPrice

        if (fieldName === 'price') {
            totalPrice = +value * (state.quantity || 0)
        }

        if (fieldName === 'quantity') {
            totalPrice = +value * (state.price || 0)
        }

        setState((prevState) => ({
            ...prevState,
            [fieldName]: value,
            totalPrice: totalPrice,
        }))
    }

    return (
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell>
                <IconButton size='large' onClick={addCustomProduct}>
                    <AddCircle color='primary' fontSize='large' />
                </IconButton>
                {
                    !isCustomProductValid && <Typography component='h5' variant='h5' color='red'>
                        Inválido!
                    </Typography>
                }
            </TableCell>

            <TableCell>
                <TextField
                    id='reference'
                    label='Referência'
                    value={state.reference}
                    variant='outlined'
                    required
                    onChange={(e) =>
                        inputChangedHandler(e.target.value, 'reference')
                    }
                />
            </TableCell>
            <TableCell component='th' scope='row'>
                <TextField
                    id='label'
                    label='Designação'
                    value={state.label}
                    variant='outlined'
                    required
                    onChange={(e) => inputChangedHandler(e.target.value, 'label')}

                />
            </TableCell>
            <TableCell>
                <TextField
                    id='quantity'
                    label='Quantidade'
                    type='number'
                    value={state.quantity}
                    variant='outlined'
                    required
                    onChange={(e) => inputChangedHandler(+e.target.value, 'quantity')}
                />
            </TableCell>
            <TableCell>
                <TextField
                    id='price'
                    label='Preço unitário'
                    type='number'
                    value={state.price}
                    variant='outlined'
                    required
                    onChange={(e) => inputChangedHandler(+e.target.value, 'price')}
                />
            </TableCell>
            <TableCell>{state.totalPrice.toFixed(2)}€</TableCell>
            <TableCell>
                <IconButton size='large' onClick={deleteCustomProduct}>
                    <Delete color='primary' fontSize='large' />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}