import { useContext, useEffect, useState } from 'react'
import { AutoCompleteSelector } from './UI/AutoCompleteSelector'
import { Product, ProductFamilyField } from '../pages/Quote/CreateQuote'
import { ProductsQuote } from '../store/ManageQuoteProvider'
import { ManageQuoteContext } from '../store/manage-quote-context'
import { useHttp } from '../hooks/use-http'

interface ProductSelectorState {
    products: ProductsQuote[]
}

interface IProductSelector {
    productFamily: ProductFamilyField,
    editMode: boolean
}

export const ProductSelector = (props: IProductSelector) => {
    const [state, setState] = useState<ProductSelectorState>({
        products: [],
    })
    const ctx = useContext(ManageQuoteContext)
    const { isLoading, error, sendRequestAndProcessData } = useHttp()

    useEffect(() => {
        (async () => {
            await getProducts()
        })()
    }, [])

    const getProducts = async () => {
        const transformProducts = (productsResult: any) => {
            const products = productsResult
                .map((product: Product) => {
                    return {
                        id: product.id,
                        label: product.name,
                        reference: product.reference,
                        price: product.price,
                        quantity: 0,
                        active: product.active,
                    }
                })

            // This was done to prevent that the user adds the DIV or MO manually
            const productsWithNoDivAndMo = products.filter((product: Product) => product.reference !== 'DIV' && product.reference !== 'MO')

            setState({
                products: productsWithNoDivAndMo,
            })

            ctx.setProductsForFamily(props.productFamily.id, products)
        }

        await sendRequestAndProcessData(
            {
                url: `product/family/${props.productFamily.value}`,
            },
            transformProducts,
        )
    }

    const sortedProducts = (products: ProductsQuote[]): ProductsQuote[] => {
        return products.sort((a, b) => a.label.localeCompare(b.label))
    }

    const activeProductsByAlphabeticOrder = (products: ProductsQuote[]): ProductsQuote[] => {
        return sortedProducts(products.filter(product => product.active))
    }

    return (
        <>
            <AutoCompleteSelector
                label='Produtos'
                data={activeProductsByAlphabeticOrder(state.products)}
                isLoading={isLoading}
                error={error != null}
                onOptionsChanged={ctx.onProductsChanged}
                productFamily={props.productFamily}
            />
        </>
    )
}
