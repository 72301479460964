import { CircularProgress } from '@mui/joy'
import Autocomplete from '@mui/joy/Autocomplete'
import { ProductFamilyField } from '../../pages/Quote/CreateQuote'
import { ProductsQuote } from '../../store/ManageQuoteProvider'
import { useState } from 'react'

export interface AutoCompleteOption {
    label: string
}

interface IAutoCompleteSelector {
    data: any[]
    isLoading: boolean
    error: boolean
    label: string
    onOptionsChanged: Function
    productFamily: ProductFamilyField
}

export const AutoCompleteSelector = (props: IAutoCompleteSelector) => {
    const [value, setValue] = useState<string | null>(null)
    const [selectedValue, setSelectedValue] = useState('')

    const onOptionsChanged = (event: any, newValue: ProductsQuote) => {
        if (newValue) {
            props.onOptionsChanged(props.productFamily, newValue)
            setValue(null)
            setSelectedValue('')
        }
    }

    const label = props.isLoading
        ? 'A carregar produtos...'
        : props.error
            ? 'Houve um erro ao listar os produtos'
            : props.label

    return (
        <Autocomplete
            id='autocomplete-selector'
            clearOnEscape
            clearOnBlur
            value={value}
            inputValue={selectedValue}
            onInputChange={(event, newInputValue) => {
                setSelectedValue(newInputValue)
            }}
            onChange={(event: any, newValue: ProductsQuote) =>
                onOptionsChanged(event, newValue)
            }
            loading={props.isLoading}
            endDecorator={
                props.isLoading ? (
                    <CircularProgress size='sm' sx={{ bgcolor: 'background.surface' }} />
                ) : null
            }
            placeholder={label}
            getOptionLabel={(option) => `${option.label} - ${option.reference}`}
            options={props.data}
        />
    )
}
