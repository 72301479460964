import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/use-auth'

export const LoggedRoute = ({ children }: any) => {
    const { currentUserToken } = useAuth()
    const location = useLocation()

    if (currentUserToken()) {
        return <Navigate to='/' replace state={{ from: location }} />
    }

    return children
}
