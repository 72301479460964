import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useContext, useState } from 'react'
import './SideNav.css'
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../store/auth-context'

interface ISideNav {
}

const settings = ['Logout']

const SideNav = (props: ISideNav) => {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
    const ctx = useContext(AuthContext)
    const navigate = useNavigate()

    const sesiLogo = '/assets/images/sesi-logo.png'

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const logout = () => {
        ctx.onLogout()
        navigate('/login')
    }

    const items = [
        {
            title: 'Criar orçamento',
            path: '/create-quote',
            authorized: ctx.hasUserRole(),
        },
        {
            title: 'Gerir produtos',
            path: '/product',
            authorized: ctx.hasAdminRole(),
        },
        {
            title: 'Gerir utilizadores',
            path: '/user',
            authorized: ctx.hasAdminRole(),
        },
    ]

    return (
        <AppBar position='static'>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>
                    <Link to='/'>
                        <img
                            className='side-nav-logo'
                            src={sesiLogo}
                            alt='SESI logo'
                            loading='lazy'
                        />
                    </Link>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            color='inherit'
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {items
                                .filter(item => item.authorized)
                                .map((item) => (
                                    <Link to={item.path} key={item.path}>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Typography textAlign='center'>
                                                {item.title}
                                            </Typography>
                                        </MenuItem>
                                    </Link>
                                ))}
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {items
                            .filter(item => item.authorized)
                            .map((item) => (
                                <Button
                                    key={item.title}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    href={item.path}
                                >
                                    {item.title}
                                </Button>
                            ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title='Open settings'>
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0 }}
                            >
                                <Avatar alt='Semy Sharp' />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id='menu-appbar'
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <div key={setting} onClick={logout}>
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <Typography textAlign='center'>
                                            {setting}
                                        </Typography>
                                    </MenuItem>
                                </div>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default SideNav
