import { MRT_ColumnDef } from 'material-react-table'
import { useState } from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { UserToManage } from './UserManager'
import { isNotEmpty } from '../../util/ValidationHelper'
import { UserRoles } from '../../store/auth-context'

interface UserModalProps {
    columns: MRT_ColumnDef<UserToManage>[];
    onClose: () => void;
    onSubmit: (values: UserToManage) => void;
    open: boolean;
}

const CreateUserModal = ({
                             open,
                             columns,
                             onClose,
                             onSubmit,
                         }: UserModalProps) => {
    const [newUserState, setNewUserState] = useState<UserToManage>(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = ''
            return acc
        }, {} as any),
    )
    const [error, setError] = useState<boolean>(false)

    const handleCancel = () => {
        setError(false)
        onClose()
    }

    const handleSubmit = () => {
        // validate user
        const isUsernameValid = isNotEmpty(newUserState.username)
        const isFirstNameValid = isNotEmpty(newUserState.firstName)
        const isLastNameValid = isNotEmpty(newUserState.lastName)
        const isPasswordValid = isNotEmpty(newUserState.password)
        const isRolesValid = newUserState.roles.length > 0

        const isUserValid = isUsernameValid && isFirstNameValid && isLastNameValid && isPasswordValid && isRolesValid

        if (isUserValid) {
            setError(false)
            //put your validation logic here
            onSubmit(newUserState)
            onClose()
        } else {
            setError(true)
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign='center'>Criar utilizador</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns
                            .filter(column => column.accessorKey != 'id')
                            .filter(column => column.accessorKey != 'active')
                            .map((column) => (
                                column.accessorKey === 'roles' ?
                                    <FormControl>
                                        <InputLabel id='demo-multiple-checkbox-label-1'>Permissões</InputLabel>
                                        <Select
                                            labelId='demo-multiple-checkbox-label'
                                            id='demo-multiple-checkbox-1'
                                            multiple
                                            value={newUserState.roles || []}
                                            onChange={(e) =>
                                                setNewUserState({
                                                    ...newUserState,
                                                    ['roles']: e.target.value as UserRoles[],
                                                })
                                            }
                                            input={<OutlinedInput label='Tag' />}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {
                                                (Object.keys(UserRoles))
                                                    .map(role =>
                                                        <MenuItem key={role} value={role}>
                                                            <Checkbox
                                                                checked={newUserState.roles.includes(role as UserRoles)} />
                                                            <ListItemText primary={role} />
                                                        </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl> :
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setNewUserState({ ...newUserState, [e.target.name]: e.target.value })
                                        }
                                    />
                            ))}
                        <TextField
                            key='password'
                            label='Password'
                            name='password'
                            type='password'
                            onChange={(e) =>
                                setNewUserState({ ...newUserState, [e.target.name]: e.target.value })
                            }
                        />
                        {error ?
                            <Typography color='error'>Existe um error em pelo menos um dos campos</Typography> : ''}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button color='secondary' onClick={handleSubmit} variant='contained'>
                    Criar utilizador
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateUserModal