import { Chip, Tab, TabList, TabPanel, Tabs } from '@mui/joy'
import { useContext, useEffect } from 'react'
import { ManageQuoteContext } from '../../../store/manage-quote-context'
import { Box } from '@mui/material'
import { ProductSelector } from '../../ProductSelector'
import { QuoteProductsTable } from './QuoteProductsTable'
import { ProductFamilySelector } from '../../ProductFamilySelector'

export const CreateQuoteTab = () => {
    const ctx = useContext(ManageQuoteContext)

    useEffect(() => console.log('WELELELELEELE'), [])
    
    // For each Tab create a product family selector
    const tabContent = ctx.products.selectedProducts.map((familyProducts) => (
        <TabPanel
            value={familyProducts.family.id}
            key={familyProducts.family.id}
        >
            <Box
                sx={{
                    '& > :not(style)': { my: 2, width: '100%' },
                }}
            >
                <ProductFamilySelector familyProducts={familyProducts} />

                {/* If value is !== 0 it means that we have already selected at least one product family, and we can then show the products selector */}
                {familyProducts.family.value !== 0 && (
                    <ProductSelector
                        key={
                            familyProducts.family.id +
                            familyProducts.family.value
                        }
                        productFamily={familyProducts.family}
                        editMode={false}
                    />
                )}

                {/* If product family is selected we should show the products table because it can be that we want to start with a custom product */}
                {familyProducts.family.value > 0 && (
                    <QuoteProductsTable
                        key={familyProducts.family.id}
                        products={familyProducts.products}
                        selectedProducts={familyProducts.selectedProducts}
                        family={familyProducts.family}
                        totalPrice={familyProducts.totalPrice}
                        divAndMoButtonClicked={familyProducts.divAndMoButtonClicked}
                    />
                )}
            </Box>
        </TabPanel>
    ))

    return (
        <Tabs
            aria-label='Basic tabs'
            defaultValue={0}
            sx={{ borderRadius: 'lg' }}
        >
            <TabList>
                {ctx.products.selectedProducts.map((option) => (
                    <Tab key={option.family.id}>
                        {option.family.name || 'Categoria'}
                    </Tab>
                ))}
                {ctx.products.showAddProductFamilyButton && (
                    <Tab>
                        <Box
                            onClick={ctx.onAddNewProductFamily}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Chip>+ Adicionar categoria</Chip>
                        </Box>
                    </Tab>
                )}
            </TabList>
            {tabContent}
        </Tabs>
    )
}
