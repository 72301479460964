import jwtDecode from 'jwt-decode'

interface DecodedToken {
    sub: string
    iss: string
    exp: number
    iat: number
    roles: string[]
}

export const isTokenValid = (token: string): boolean => {
    try {
        const decodedToken = jwtDecode<DecodedToken>(token)
        const issuer = decodedToken.iss
        const expirationDate = new Date(decodedToken.exp * 1000)
        const currentTimestamp = new Date().getTime()
        return (
            currentTimestamp < expirationDate.getTime() &&
            issuer === 'instant-quote'
        )
    } catch (error) {
        return false // Invalid token or decoding error
    }
}

export const getUserRoles = (token: string): string[] => {
    const decodedToken = jwtDecode<DecodedToken>(token)
    return decodedToken.roles
}