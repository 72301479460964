import { Box, MenuItem, TextField, Typography } from '@mui/material'
import { BooleanObject } from '../../../pages/Quote/CreateQuote'
import { enumGetKeyByValue } from '../../../util/EnumHelper'

export interface QuoteHeader {
    customerHandler: CustomerHandlerKey
    customer: string
    address: string
    postalCode: string
    constructionSupervisor?: string
    subject?: string
    construction?: string
    paymentConditions?: string
}

export enum CustomerHandler {
    COMPANY = 'Empresa',
    EXMO_SENHOR = 'Exmo Senhor',
    EXMA_SENHORA = 'Exma Senhora'
}

export type CustomerHandlerKey = keyof typeof CustomerHandler

interface IQuoteHeader {
    data: QuoteHeader
    dataValidity: BooleanObject
    inputChangedHandler: Function
}

export const QuoteHeader = (props: IQuoteHeader) => {
    const {
        customerHandler: customerHandlerValue,
        customer,
        address,
        postalCode,
        constructionSupervisor,
        paymentConditions,
        subject,
        construction,
    } = props.data

    const {
        customerHandler: customerHandlerValidity,
        customer: customerValidity,
        constructionSupervisor: constructionSupervisorValidity,
        address: addressValidity,
        postalCode: postalCodeValidity,
    } = props.dataValidity

    const inputs = [
        {
            id: 'customerHandler',
            label: 'Saudação',
            value: customerHandlerValue,
            select: true,
            onChange: props.inputChangedHandler,
            children: Object.values(CustomerHandler).map((handler) => (
                <MenuItem key={enumGetKeyByValue(CustomerHandler, handler)}
                          value={enumGetKeyByValue(CustomerHandler, handler)}>
                    {handler}
                </MenuItem>
            )),
            isValid: customerHandlerValidity,
            invalidLabel: 'Introduza uma saudação',
            required: true,
        },
        {
            id: 'customer',
            label: 'Cliente',
            value: customer,
            onChange: props.inputChangedHandler,
            isValid: customerValidity,
            invalidLabel: 'Introduza um cliente',
            required: true,
        },
        {
            id: 'constructionSupervisor',
            label: 'Ao cuidado de',
            value: constructionSupervisor,
            onChange: props.inputChangedHandler,
            hidden: customerHandlerValue !== enumGetKeyByValue(CustomerHandler, CustomerHandler.COMPANY),
            isValid: constructionSupervisorValidity,
            invalidLabel: 'Introduza um supervisor para a obra',
            required: true,
        },
        {
            id: 'address',
            label: 'Morada',
            value: address,
            onChange: props.inputChangedHandler,
            isValid: addressValidity,
            invalidLabel: 'Introduza uma morada',
            required: true,
        },
        {
            id: 'postalCode',
            label: 'Código P. e localidade',
            value: postalCode,
            onChange: props.inputChangedHandler,
            isValid: postalCodeValidity,
            invalidLabel: 'Introduza um código postal',
            required: true,
        },
        {
            id: 'subject',
            label: 'Assunto',
            value: subject,
            onChange: props.inputChangedHandler,
        },
        {
            id: 'construction',
            label: 'Obra',
            value: construction,
            onChange: props.inputChangedHandler,
        },
        // {
        //     id: 'paymentConditions',
        //     label: 'Condições de pagamento',
        //     value: paymentConditions,
        //     select: false,
        //     onChange: props.inputChangedHandler,
        // },
    ]

    return (
        <Box
            sx={{
                '& > :not(style)': { m: 2, width: '25ch' },
            }}
        >
            <Typography
                gutterBottom
                variant='h4'
                component='div'
                sx={{ marginBottom: '1rem' }}
            >
                Dados do cliente
            </Typography>
            {
                inputs.filter(input => input.hidden === undefined || !input.hidden).map(input => (
                    <TextField
                        key={input.id}
                        id={input.id}
                        label={input.label}
                        value={input.value}
                        select={input.select !== undefined && input.select}
                        onChange={(event) =>
                            props.inputChangedHandler(event.target.value, input.id)
                        }
                        required={input.required}
                        error={input.isValid !== undefined ? !input.isValid : false}
                        helperText={input.isValid !== undefined && !input.isValid ? input.invalidLabel : ''}
                    >
                        {input.children}
                    </TextField>
                ))
            }
        </Box>
    )
}