import {
    Button,
    ButtonGroup,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import { FamilyProducts, ProductsQuote } from '../../../store/ManageQuoteProvider'
import { useContext, useState } from 'react'
import { ManageQuoteContext } from '../../../store/manage-quote-context'
import { AddCircle, ArrowDropDown, ArrowDropUp, Delete } from '@mui/icons-material'
import { QuoteProductsTableCustomProduct } from './QuoteProductsTableCustomProduct'

const columns = [
    'Mover',
    'Referência',
    'Designação',
    'Quantidade',
    'Preço unitário',
    'Preço total',
    '',
]

interface QuoteProductsTableState {
    showAddCustomProductButton: boolean,
}

export const QuoteProductsTable = (props: FamilyProducts) => {
    const [state, setState] = useState<QuoteProductsTableState>({
        showAddCustomProductButton: true,
    })
    const ctx = useContext(ManageQuoteContext)

    const addCustomProduct = () => {
        setState((prevState) => ({
            ...prevState,
            showAddCustomProductButton: false,
        }))
    }

    const onCustomProductAddedHandler = () => {
        setState((prevState) => ({
            ...prevState,
            showAddCustomProductButton: true,
        }))
    }

    const onDeleteProductHandler = (productLabel: string) => {
        const familyId = props.family.id
        ctx.onDeleteProduct(productLabel, familyId)
    }

    const onAddDivAndMoButtonHandler = () => {
        // This was done in two different steps to order the products by label and keep
        // the presentation order of DIV -> MO
        ctx.getProductsForFamily(props.family.id)
            .filter((product: ProductsQuote) => product.reference === 'DIV')
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((product: ProductsQuote) => ctx.onProductsChanged(props.family, product))
        ctx.getProductsForFamily(props.family.id)
            .filter((product: ProductsQuote) => product.reference === 'MO')
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((product: ProductsQuote) => ctx.onProductsChanged(props.family, product))
        ctx.divAndMoButtonClicked(props.family.id)
    }

    const tableFooter = <TableFooter>
        <TableRow sx={{
            '&:last-child td, &:last-child th': {
                border: 0,
            },
        }}>
            <TableCell>
                {
                    state.showAddCustomProductButton &&
                    <IconButton size='large' onClick={addCustomProduct}>
                        <AddCircle color='primary' fontSize='large' />
                    </IconButton>
                }

            </TableCell>
            <TableCell></TableCell>
            <TableCell><Button variant='contained' onClick={onAddDivAndMoButtonHandler}>Adicionar itens
                obrigatórios</Button></TableCell>
            <TableCell></TableCell>
            <TableCell>
                <Typography fontWeight='bold'>
                    Preço total:
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight='bold'>
                    {props.totalPrice.toFixed(2) || 0}€
                </Typography>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableFooter>

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 650, border: '2px solid green' }}
                aria-label='Table de produtos do orçamento'
            >
                <TableHead>
                    <TableRow>
                        {columns.map((column) => {
                            return <TableCell key={column}>{column}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.selectedProducts.map((product, index) => {
                        return (
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                                key={product.reference + product.label}
                            >
                                <TableCell>
                                    <ButtonGroup
                                        orientation='vertical'
                                        aria-label='vertical contained button group'
                                        variant='contained'
                                    >
                                        <Button
                                            key='up'
                                            disabled={index === 0}
                                            onClick={(event) => ctx.moveProductUp(props.family.id, index)}
                                        >
                                            <ArrowDropUp />
                                        </Button>
                                        <Button
                                            key='down'
                                            disabled={index === props.selectedProducts.length - 1}
                                            onClick={(event) => ctx.moveProductDown(props.family.id, index)}
                                        >
                                            <ArrowDropDown />
                                        </Button>
                                    </ButtonGroup>
                                </TableCell>
                                <TableCell>{product.reference}</TableCell>
                                <TableCell component='th' scope='row'>
                                    {product.label}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        id='outlined-basic'
                                        label='Unidades'
                                        type='number'
                                        inputProps={{
                                            pattern: '[0-9]*',
                                        }}
                                        value={product.quantity === 0 ? undefined : product.quantity}
                                        variant='outlined'
                                        required
                                        onChange={(event) =>
                                            ctx.onAddQuantityToProduct(
                                                event,
                                                props.family,
                                                product,
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        id='outlined-basic'
                                        label='Preço unitário'
                                        type='number'
                                        inputProps={{
                                            pattern: '^(?:\\d+|\\d+[.,]\\d+)$\n',
                                        }}
                                        required
                                        variant='outlined'
                                        value={product.price === 0 ? undefined : product.price}
                                        onChange={(event) =>
                                            ctx.onChangeProductUnitaryPrice(
                                                event,
                                                props.family,
                                                product,
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    {product.totalPrice.toFixed(2) || 0}€
                                </TableCell>
                                <TableCell>
                                    <IconButton size='large'
                                                onClick={(event) => onDeleteProductHandler(product.label)}>
                                        <Delete color='primary' fontSize='large' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    {/* This is the row to add custom products */}
                    {!state.showAddCustomProductButton &&
                        <QuoteProductsTableCustomProduct onCustomProductAddedHandler={onCustomProductAddedHandler}
                                                         productFamily={props.family} />}
                </TableBody>
                {tableFooter}
            </Table>
        </TableContainer>
    )
}
