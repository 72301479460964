import { enumGetKeyByValue } from './EnumHelper'
import { CustomerHandler } from '../components/Quote/Mutate/QuoteHeader'
import { BooleanObject, QuoteState } from '../pages/Quote/CreateQuote'
import { ManageQuoteContextValue } from '../store/manage-quote-context'

export const isNotEmpty = (value: string | null | undefined) => value !== null && value !== undefined && value.trim() !== ''

export const isQuoteValid = (
    state: QuoteState,
    ctx: ManageQuoteContextValue,
    setFormInputsValidity: React.Dispatch<React.SetStateAction<BooleanObject>>,
): boolean => {
    const enteredCustomerHandlerIsValid = isNotEmpty(state.customerHandler?.toString())
    const enteredCustomerIsValid = isNotEmpty(state.customer)
    const enteredConstructionSupervisorIsValid =
        state.customerHandler !== enumGetKeyByValue(CustomerHandler, CustomerHandler.COMPANY) ? true :
            isNotEmpty(state.constructionSupervisor)
    const enteredAddressIsValid = isNotEmpty(state.address)
    const enteredPostalCodeIsValid = isNotEmpty(state.postalCode)
    const enteredDownloadFileTypeIsValid = state.downloadFileType !== undefined
    const computerProducts = ctx.productsForRequest()
    const enteredProductsAreValid =
        !computerProducts.some(product =>
            product.reference === undefined || product.reference.trim() === '' ||
            product.name === undefined || product.name.trim() === '' ||
            product.quantity === undefined || product.quantity <= 0 ||
            product.price === undefined || product.price < 0,
        )
    const wasDivAndMoButtonClicked = ctx.products.selectedProducts.every(f => f.divAndMoButtonClicked)
    const atLeastOneExistingProductSelected = ctx.products.selectedProducts.every(selectedProducts => selectedProducts.selectedProducts.some(product => product.id > 0))

    setFormInputsValidity({
        customerHandler: enteredCustomerHandlerIsValid,
        customer: enteredCustomerIsValid,
        constructionSupervisor: enteredConstructionSupervisorIsValid,
        address: enteredAddressIsValid,
        postalCode: enteredPostalCodeIsValid,
        downloadFileType: enteredDownloadFileTypeIsValid,
        products: enteredProductsAreValid,
        wasDivAndMoButtonClicked: wasDivAndMoButtonClicked,
        atLeastOneExistingProductSelected: atLeastOneExistingProductSelected,
    })

    const formIsValid = enteredCustomerHandlerIsValid &&
        enteredCustomerIsValid &&
        enteredConstructionSupervisorIsValid &&
        enteredAddressIsValid &&
        enteredPostalCodeIsValid &&
        enteredDownloadFileTypeIsValid &&
        enteredProductsAreValid &&
        wasDivAndMoButtonClicked &&
        atLeastOneExistingProductSelected

    return formIsValid
}