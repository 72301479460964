import { ChangeEvent, useReducer } from 'react'
import {
    CreateQuoteProductRequest,
    CreateQuoteRequest,
    ProductFamily,
    ProductFamilyField,
} from '../pages/Quote/CreateQuote'
import { ManageQuoteContext } from './manage-quote-context'
import { useHttp } from '../hooks/use-http'

export interface ProductsQuote {
    id: number
    quoteRowIndex: number
    label: string
    reference: string
    price?: number
    quantity?: number
    totalPrice: number
    active: boolean
}

export interface FamilyProducts {
    family: ProductFamilyField
    products: ProductsQuote[]
    selectedProducts: ProductsQuote[]
    totalPrice: number
    divAndMoButtonClicked: boolean
}

export interface ManageQuoteState {
    selectedProducts: FamilyProducts[]
    showAddProductFamilyButton: boolean
    productFamilies: ProductFamily[]
}

export const calculateQuoteTotalPrice = (products: ProductsQuote[]) => {
    return products.reduce(
        (accumulator, product) =>
            accumulator + product.totalPrice,
        0,
    )
}

const defaultManageQuoteState: ManageQuoteState = {
    selectedProducts: [
        {
            family: {
                id: 0,
                value: 0,
                name: '',
            },
            products: [],
            selectedProducts: [],
            totalPrice: 0,
            divAndMoButtonClicked: false,
        },
    ],
    showAddProductFamilyButton: false,
    productFamilies: [],
}

const moveElement = (array: ProductsQuote[], fromIndex: number, toIndex: number) => {
    const arrayCopy = [...array]
    const element = arrayCopy.splice(fromIndex, 1)[0]
    arrayCopy.splice(toIndex, 0, element)
    return arrayCopy
}

function moveProductOnArray(productFamilyIndex: number, productIndex: number, state: ManageQuoteState, moveUp: boolean) {
    const familyProduct = getFamilyProduct(state, productFamilyIndex)
    const products = familyProduct.selectedProducts
    const updatedProductFamily: FamilyProducts = {
        ...familyProduct,
        selectedProducts: moveElement(products, productIndex, moveUp ? productIndex - 1 : productIndex + 1),
    }
    const updatedSelectedProducts = updateSelectedProducts(state, updatedProductFamily)

    return {
        ...state,
        selectedProducts: updatedSelectedProducts,
    }
}

function updateProduct(state: ManageQuoteState, action: any, updatedProduct: ProductsQuote) {
    // DIFFERENT APPROACH
    // // Get correct family element
    // const existingProductFamilyIndex = state.selectedProducts.findIndex(
    //     (item) => item.family === action.family
    // )
    // const existingProductFamily =
    //     state.selectedProducts[existingProductFamilyIndex]

    // // Get products array
    // const existingProductIndex = existingProductFamily.products.findIndex(
    //     (item) => item.reference === action.changedProduct.reference
    // )
    // const existingProduct =
    //     existingProductFamily.products[existingProductIndex]

    // if (!existingProductFamily || !existingProduct) {
    //     throw new Error('Could not find product family')
    // }

    // // Update product with new quantity
    // const updatedProduct = {
    //     ...existingProduct,
    //     quantity: action.quantity,
    // }

    // // Update products array with the updated product with the new quantity
    // let updatedProducts: ProductsQuote[] = [
    //     ...existingProductFamily.products,
    // ]
    // updatedProducts[existingProductIndex] = updatedProduct

    // // Update the FamilyProducts
    // const updatedFamilyProduct: FamilyProducts = {
    //     family: existingProductFamily.family,
    //     products: updatedProducts,
    // }
    const productFamily = state.selectedProducts
        .find(
            (productsPerFamilyObj) =>
                productsPerFamilyObj.family === action.family,
        )!!

    const updatedProducts = productFamily.selectedProducts.map((product) => {
        if (product.label === action.changedProduct.label) {
            return updatedProduct
        }
        return product
    })

    if (!updatedProducts) {
        throw new Error('Could not update products')
    }

    const updatedProductFamily: FamilyProducts = {
        ...productFamily,
        family: action.family,
        selectedProducts: updatedProducts,
        totalPrice: calculateQuoteTotalPrice(updatedProducts),
    }
    const updatedSelectedProducts = updateSelectedProducts(state, updatedProductFamily)

    return updatedSelectedProducts
}

function updateSelectedProducts(state: ManageQuoteState, updatedProductFamily: FamilyProducts) {
    return state.selectedProducts.map(
        (familyProducts) =>
            familyProducts.family.id === updatedProductFamily.family.id
                ? updatedProductFamily
                : familyProducts,
    )
}

function addProduct(state: ManageQuoteState, productFamily: ProductFamilyField, customProduct: ProductsQuote, action: any) {
    const family = getFamilyProduct(state, productFamily.id)
    const familyProducts = family.selectedProducts

    // Check that the custom product we are trying to create does not already exist
    // We cannot use the reference to check because we can have multiple products
    // with the same reference (f.e. DIV reference)
    if (!familyProducts.some(product => product.label === customProduct.label)) {
        // If we want to add product as the first index
        //familyProducts.unshift(customProduct)
        // If we want to add product as the last index
        familyProducts.push(customProduct)
    }

    const updatedProductFamily: FamilyProducts = {
        ...family,
        family: action.productFamily,
        selectedProducts: familyProducts,
        totalPrice: calculateQuoteTotalPrice(familyProducts),
    }
    const updatedSelectedProducts = updateSelectedProducts(state, updatedProductFamily)
    return updatedSelectedProducts
}

function getFamilyProduct(state: ManageQuoteState, familyId: number) {
    return state.selectedProducts.find(productFamily => productFamily.family.id === familyId)!!
}

enum ManageQuoteProviderAction {
    SET_PRODUCTS_FOR_FAMILY = 'SET_PRODUCTS_FOR_FAMILY',
    LOAD_STATE = 'LOAD_STATE',
    PRODUCT_FAMILIES_LOAD = 'PRODUCT_FAMILIES_LOAD',
    MOVE_PRODUCT_UP = 'MOVE_PRODUCT_UP',
    MOVE_PRODUCT_D0WN = 'MOVE_PRODUCT_D0WN',
    PRODUCT_FAMILIES_ADD = 'PRODUCT_FAMILIES_ADD',
    PRODUCT_FAMILIES_CHANGED = 'PRODUCT_FAMILIES_CHANGED',
    CUSTOM_PRODUCT_ADD = 'CUSTOM_PRODUCT_ADD',
    PRODUCTS_CHANGED = 'PRODUCTS_CHANGED',
    PRODUCTS_QUANTITY_CHANGED = 'PRODUCTS_QUANTITY_CHANGED',
    PRODUCTS_UNITARY_PRICE_CHANGED = 'PRODUCTS_UNITARY_PRICE_CHANGED',
    DIV_AND_MO_BUTTON_CLICKED = 'DIV_AND_MO_BUTTON_CLICKED',
    DELETE_PRODUCT = 'DELETE_PRODUCT',
    CLEAR_STATE = 'CLEAR_STATE'
}

const mangeQuoteReducer = (
    state: ManageQuoteState,
    action: any,
): ManageQuoteState => {
    if (action.type === ManageQuoteProviderAction.SET_PRODUCTS_FOR_FAMILY) {
        const familyId = action.familyId
        const products = action.products
        const familyProduct = getFamilyProduct(state, familyId)
        const updatedProductFamily: FamilyProducts = {
            ...familyProduct,
            products: products,
        }
        const updatedSelectedProducts = updateSelectedProducts(state, updatedProductFamily)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
        }
    }

    if (action.type === ManageQuoteProviderAction.LOAD_STATE) {
        return action.manageQuoteState
    }

    if (action.type === ManageQuoteProviderAction.PRODUCT_FAMILIES_LOAD) {
        return {
            ...state,
            productFamilies: action.productFamilies,
        }
    }

    if (action.type === ManageQuoteProviderAction.MOVE_PRODUCT_UP) {
        const productFamilyIndex = action.productFamilyIndex
        const productIndex = action.productIndex
        return moveProductOnArray(productFamilyIndex, productIndex, state, true)
    }

    if (action.type === ManageQuoteProviderAction.MOVE_PRODUCT_D0WN) {
        const productFamilyIndex = action.productFamilyIndex
        const productIndex = action.productIndex
        return moveProductOnArray(productFamilyIndex, productIndex, state, false)
    }

    if (action.type === ManageQuoteProviderAction.PRODUCT_FAMILIES_ADD) {
        const productFamilyIds = state.selectedProducts.map((t) => t.family.id)

        const mostRecentId: number =
            state.selectedProducts.length === 0
                ? 0
                : Math.max(...productFamilyIds)

        const newProductFamily: FamilyProducts = {
            family: {
                id: mostRecentId + 1,
                value: 0,
                name: '',
            },
            products: [],
            selectedProducts: [],
            totalPrice: 0,
            divAndMoButtonClicked: false,
        }

        return {
            ...state,
            selectedProducts: [...state.selectedProducts, newProductFamily],
            showAddProductFamilyButton: false,
        }
    }

    if (action.type === ManageQuoteProviderAction.PRODUCT_FAMILIES_CHANGED) {
        const productFamilyName = state.productFamilies.find(
            (productFamily) => productFamily.id === action.productFamilyValue,
        )

        const updatedProductFamily: FamilyProducts = {
            family: {
                id: action.productFamily.id,
                value: action.productFamilyValue,
                name: productFamilyName?.name ?? '',
            },
            products: [],
            selectedProducts: [],
            totalPrice: 0,
            divAndMoButtonClicked: false,
        }

        const updatedSelectedProducts = updateSelectedProducts(state, updatedProductFamily)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
            showAddProductFamilyButton: true,
        }
    }

    if (action.type === ManageQuoteProviderAction.CUSTOM_PRODUCT_ADD) {
        const customProduct = action.customProduct
        const customProductToAdd: ProductsQuote = {
            ...customProduct,
            totalPrice: (customProduct.quantity || 0) * (customProduct.price || 0),
        }
        const productFamily = action.productFamily
        const updatedSelectedProducts = addProduct(state, productFamily, customProductToAdd, action)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
        }
    }

    if (action.type === ManageQuoteProviderAction.PRODUCTS_CHANGED) {
        const newProduct: ProductsQuote = action.newProduct
        const productToAdd: ProductsQuote = {
            ...newProduct,
            totalPrice: (newProduct.quantity || 0) * (newProduct.price || 0),
        }
        const productFamily = action.productFamily
        const updatedSelectedProducts = addProduct(state, productFamily, productToAdd, action)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
        }
    }

    if (action.type === ManageQuoteProviderAction.PRODUCTS_QUANTITY_CHANGED) {
        const updatedProduct: ProductsQuote = {
            ...action.changedProduct,
            quantity: action.newQuantity,
            totalPrice: action.newQuantity * (action.changedProduct.price || 0),
        }
        const updatedSelectedProducts = updateProduct(state, action, updatedProduct)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
        }
    }

    if (action.type === ManageQuoteProviderAction.PRODUCTS_UNITARY_PRICE_CHANGED) {
        const updatedProduct: ProductsQuote = {
            ...action.changedProduct,
            price: action.newUnitaryPrice,
            totalPrice: (action.changedProduct.quantity || 0) * action.newUnitaryPrice,
        }
        const updatedSelectedProducts = updateProduct(state, action, updatedProduct)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
        }
    }

    if (action.type === ManageQuoteProviderAction.DELETE_PRODUCT) {
        const productLabel = action.productLabel
        const familyId = action.familyId
        const familyProduct = getFamilyProduct(state, familyId)
        const products = familyProduct.selectedProducts
        const updatedProducts = products.filter(
            (product) => product.label !== productLabel,
        )

        const updatedProductFamily: FamilyProducts = {
            ...familyProduct,
            selectedProducts: updatedProducts,
        }
        const updatedSelectedProducts = updateSelectedProducts(state, updatedProductFamily)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
        }
    }

    if (action.type === ManageQuoteProviderAction.DIV_AND_MO_BUTTON_CLICKED) {
        const familyId = action.familyId
        const familyProduct = getFamilyProduct(state, familyId)
        const updatedProductFamily: FamilyProducts = {
            ...familyProduct,
            divAndMoButtonClicked: true,
        }
        const updatedSelectedProducts = updateSelectedProducts(state, updatedProductFamily)

        return {
            ...state,
            selectedProducts: updatedSelectedProducts,
        }
    }

    if (action.type === ManageQuoteProviderAction.CLEAR_STATE) {
        return defaultManageQuoteState
    }

    throw new Error('Action not supported')
}

export const ManageQuoteProvider = ({ children }: any) => {
    const [state, dispatchManageQuoteAction] = useReducer(
        mangeQuoteReducer,
        defaultManageQuoteState,
    )
    const { sendDownloadResourceRequest, isLoading, error, status } = useHttp()

    const getProductsForFamilyHandler = (familyId: number): ProductsQuote[] => {
        const familyProduct = getFamilyProduct(state, familyId)
        return familyProduct.products
    }

    const setProductsForFamilyHandler = (familyId: number, products: ProductsQuote[]) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.SET_PRODUCTS_FOR_FAMILY,
            familyId: familyId,
            products: products,
        })
    }

    const loadProductFamiliesHandler = (productFamilies: ProductFamily[]) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.PRODUCT_FAMILIES_LOAD,
            productFamilies: productFamilies,
        })
    }

    const moveProductUpHandler = (productFamilyIndex: number, productIndex: number) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.MOVE_PRODUCT_UP,
            productFamilyIndex: productFamilyIndex,
            productIndex: productIndex,
        })
    }

    const moveProductDownHandler = (productFamilyIndex: number, productIndex: number) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.MOVE_PRODUCT_D0WN,
            productFamilyIndex: productFamilyIndex,
            productIndex: productIndex,
        })
    }

    const productFamilyChangedHandler = (
        productFamilyValue: string,
        productFamily: ProductFamilyField,
    ) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.PRODUCT_FAMILIES_CHANGED,
            productFamilyValue: productFamilyValue,
            productFamily: productFamily,
        })
    }

    const productsChangedHandler = (
        productFamily: ProductFamilyField,
        newProduct: ProductsQuote,
    ) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.PRODUCTS_CHANGED,
            productFamily: productFamily,
            newProduct: newProduct,
        })
    }

    const addCustomProductHandler = (customProduct: ProductsQuote, productFamily: ProductFamilyField) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.CUSTOM_PRODUCT_ADD,
            customProduct: customProduct,
            productFamily: productFamily,
        })
    }

    const createNewProductFamilySelect = () => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.PRODUCT_FAMILIES_ADD,
        })
    }

    const productQuantityChangedHandler = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        family: ProductFamilyField,
        changedProduct: ProductsQuote,
    ) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.PRODUCTS_QUANTITY_CHANGED,
            newQuantity: +event.target.value,
            family: family,
            changedProduct: changedProduct,
        })
    }

    const productUnitaryPriceChangedHandler = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        family: ProductFamilyField,
        changedProduct: ProductsQuote,
    ) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.PRODUCTS_UNITARY_PRICE_CHANGED,
            newUnitaryPrice: +event.target.value,
            family: family,
            changedProduct: changedProduct,
        })
    }

    const divAndMoButtonClickedHandler = (familyId: number) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.DIV_AND_MO_BUTTON_CLICKED,
            familyId: familyId,
        })
    }

    const loadStateHandler = (manageQuoteState: ManageQuoteState) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.LOAD_STATE,
            manageQuoteState: manageQuoteState,
        })
    }

    const deleteProductHandler = (productLabel: string, familyId: number) => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.DELETE_PRODUCT,
            productLabel: productLabel,
            familyId: familyId,
        })
    }

    const clearStateHandler = () => {
        dispatchManageQuoteAction({
            type: ManageQuoteProviderAction.CLEAR_STATE,
        })
    }

    const createQuote = async (createQuoteState: CreateQuoteRequest): Promise<void> => {
        const headers = {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/json',
        }

        const request = {
            customerHandler: createQuoteState.customerHandler,
            customer: createQuoteState.customer,
            address: createQuoteState.address,
            postalCode: createQuoteState.postalCode,
            products: createProductsRequest(),
            downloadFileType: createQuoteState.downloadFileType,
            generateWithTotalPriceOnly: createQuoteState.generateWithTotalPriceOnly,
            quoteFor24hConnectionService: createQuoteState.quoteFor24hConnectionService,
            constructionSupervisor: createQuoteState.constructionSupervisor,
            subject: createQuoteState.subject,
            construction: createQuoteState.construction,
            paymentConditions: createQuoteState.paymentConditions,
        }

        await sendDownloadResourceRequest({
            url: 'quote',
            method: 'POST',
            headers,
            body: request,
        }).catch(err => {
            throw new Error(err)
        })
    }

    const createProductsRequest = (): CreateQuoteProductRequest[] => {
        let result: CreateQuoteProductRequest[] = []
        state.selectedProducts.forEach((productFamilies) => {
            productFamilies.selectedProducts.forEach((product, index) => {
                result.push(
                    {
                        quoteIndex: productFamilies.family.id,
                        quoteRowIndex: index,
                        productId: product.id,
                        reference: product.reference,
                        name: product.label,
                        productFamily: +productFamilies.family.value,
                        price: product.price,
                        quantity: product.quantity,
                    } as CreateQuoteProductRequest,
                )
            })
        })
        return result
    }

    const updateQuote = async (quoteId: number, createQuoteState: CreateQuoteRequest): Promise<void> => {
        const headers = {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/json',
        }

        const request = {
            customerHandler: createQuoteState.customerHandler,
            customer: createQuoteState.customer,
            address: createQuoteState.address,
            postalCode: createQuoteState.postalCode,
            products: createProductsRequest(),
            downloadFileType: createQuoteState.downloadFileType,
            generateWithTotalPriceOnly: createQuoteState.generateWithTotalPriceOnly,
            quoteFor24hConnectionService: createQuoteState.quoteFor24hConnectionService,
            constructionSupervisor: createQuoteState.constructionSupervisor,
            subject: createQuoteState.subject,
            construction: createQuoteState.construction,
            paymentConditions: createQuoteState.paymentConditions,
        }

        await sendDownloadResourceRequest({
            url: `quote/${quoteId}`,
            method: 'PUT',
            headers,
            body: request,
        }).catch(err => {
            throw new Error(err)
        })
    }

    return (
        <ManageQuoteContext.Provider
            value={{
                products: state,
                getProductsForFamily: getProductsForFamilyHandler,
                setProductsForFamily: setProductsForFamilyHandler,
                moveProductUp: moveProductUpHandler,
                moveProductDown: moveProductDownHandler,
                onLoadProductFamilies: loadProductFamiliesHandler,
                onProductsChanged: productsChangedHandler,
                onAddCustomProduct: addCustomProductHandler,
                onProductFamilyChanged: productFamilyChangedHandler,
                onAddNewProductFamily: createNewProductFamilySelect,
                onAddQuantityToProduct: productQuantityChangedHandler,
                onDeleteProduct: deleteProductHandler,
                onCreateQuote: createQuote,
                onUpdateQuote: updateQuote,
                onChangeProductUnitaryPrice: productUnitaryPriceChangedHandler,
                productsForRequest: createProductsRequest,
                divAndMoButtonClicked: divAndMoButtonClickedHandler,
                loadState: loadStateHandler,
                clearState: clearStateHandler,
            }}
        >
            {children}
        </ManageQuoteContext.Provider>
    )
}
