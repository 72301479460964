import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import SideNav from '../components/UI/SideNav'
import '../theme/global.css'

const Layout = () => {
    return (
        <>
            <SideNav />
            <Container className='main-container' disableGutters maxWidth='xl'>
                <Outlet />
            </Container>
        </>
    )
}

export default Layout
