import MaterialReactTable, { MaterialReactTableProps, MRT_Cell, MRT_ColumnDef } from 'material-react-table'
import { Box, Button, Checkbox, FormControlLabel, IconButton, MenuItem, TextField, Tooltip } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Cancel, CheckCircle, Edit } from '@mui/icons-material'
import { useHttp } from '../../hooks/use-http'
import { Product, ProductFamily } from '../Quote/CreateQuote'
import CreateProductModal from './CreateProductModal'

const ProductManager = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [productFamilies, setProductFamilies] = useState<ProductFamily[]>([])
    const [productFamily, setProductFamily] = useState<ProductFamily>()
    const [tableData, setTableData] = useState<Product[]>([])
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({})
    const { isLoading, sendRequestAndProcessData } = useHttp()

    useEffect(() => {
        (async () => {
            await getProductFamilies()
        })()
    }, [productFamily])

    const getProductFamilies = async () => {
        const transformProductFamilies = (productFamilies: ProductFamily[]) => {
            const sortedData = productFamilies.sort(
                (a: ProductFamily, b: ProductFamily) =>
                    a.name.localeCompare(b.name),
            )

            setProductFamilies(sortedData)
        }

        await sendRequestAndProcessData(
            {
                url: 'product/family',
            },
            transformProductFamilies,
        )
    }

    const productFamilyChangedHandler = async (productFamilyId: string) => {
        const selectedProductFamily = productFamilies.find(family => family.id === +productFamilyId)!
        setProductFamily(selectedProductFamily)
        await getProducts(productFamilyId)
    }

    const getProducts = async (productFamilyId: string) => {
        const transformQuotes = (products: Product[]) => {
            setTableData(products)
        }

        await sendRequestAndProcessData(
            {
                url: `product/family/${productFamilyId}`,
                method: 'GET',
            },
            transformQuotes,
        )
    }

    const createProduct = async (product: Product) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        const productToSave = {
            ...product,
            productFamily: productFamily!.id,
        }

        await sendRequestAndProcessData(
            {
                url: `product`,
                method: 'POST',
                headers: headers,
                body: productToSave,
            },
            () => {
            },
        )
    }

    const updateProduct = async (productId: string, product: Product) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        const productToSave = {
            ...product,
            productFamily: productFamily!.id,
        }

        await sendRequestAndProcessData(
            {
                url: `product/${productId}`,
                method: 'PUT',
                headers: headers,
                body: productToSave,
            },
            () => {
            },
        )
    }

    const handleCreateNewRow = async (values: Product) => {
        tableData.push(values)
        await createProduct(values)
        setTableData([...tableData])
    }

    const handleSaveRowEdits: MaterialReactTableProps<Product>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                tableData[row.index] = values
                const productId: string = row.getValue('id')
                await updateProduct(productId, values)
                //send/receive api updates here, then refetch or update local table data for re-render
                setTableData([...tableData])
                exitEditingMode() //required to exit editing mode and close modal
            }
        }

    const handleCancelRowEdits = () => {
        setValidationErrors({})
    }

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<Product>,
        ): MRT_ColumnDef<Product>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'price'
                            ? validatePrice(+event.target.value)
                            : validateRequired(event.target.value)
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} não é válido`,
                        })
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id]
                        setValidationErrors({
                            ...validationErrors,
                        })
                    }
                },
            }
        },
        [validationErrors],
    )

    const columns = useMemo<MRT_ColumnDef<Product>[]>(
        () => [
            {
                id: 'id',
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                size: 80,
            },
            {
                id: 'reference',
                accessorKey: 'reference',
                header: 'Referência',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                id: 'name',
                accessorKey: 'name',
                header: 'Nome',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                id: 'price',
                accessorKey: 'price',
                header: 'Preço',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                id: 'baseUnit',
                accessorKey: 'baseUnit',
                header: 'Unidade',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                id: 'active',
                accessorKey: 'active',
                header: 'Ativo',
                Cell: ({ cell }) => (
                    cell.getValue() as boolean ?
                        <CheckCircle fontSize='large' color='success' /> :
                        <Cancel fontSize='large' color='error' />
                ),
                Edit: ({ cell, row, column }) => {
                    const [activeState, setActiveState] = useState<boolean>(cell.getValue() as boolean)

                    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                        const newActive = event.target.checked
                        setActiveState(newActive)
                        row._valuesCache[column.id] = newActive
                    }

                    return <FormControlLabel
                        required
                        control={
                            <Checkbox
                                checked={activeState}
                                onChange={onChange}
                            />}
                        label='Ativo'
                    />
                },
            },
        ],
        [getCommonEditTextFieldProps],
    )

    return (
        <>
            <TextField
                id={`product-families`}
                select
                label='Categoria de produtos'
                onChange={(event) =>
                    productFamilyChangedHandler(event.target.value)
                }
                fullWidth
                value={productFamily}
            >
                {productFamilies.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
            {productFamily && (
                <>
                    <MaterialReactTable
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 120,
                            },
                        }}
                        columns={columns}
                        data={tableData}
                        editingMode='modal' //default
                        enableColumnOrdering
                        enableEditing
                        onEditingRowSave={handleSaveRowEdits}
                        onEditingRowCancel={handleCancelRowEdits}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement='left' title='Edit'>
                                    <IconButton onClick={() => table.setEditingRow(row)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        renderTopToolbarCustomActions={() => (
                            <Button
                                color='secondary'
                                onClick={() => setModalOpen(true)}
                                variant='contained'
                            >
                                Criar produto
                            </Button>
                        )}
                        state={{
                            isLoading: isLoading,
                        }}
                    />
                    <CreateProductModal
                        columns={columns}
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        onSubmit={handleCreateNewRow}
                    />
                </>
            )}
        </>
    )
}

const validateRequired = (value: string) => !!value.length
const validatePrice = (price: number) => price >= 0

export default ProductManager